import React, { useState } from "react";
import classNames from "classnames";
import endpoint from "utils/endpoint";
import axios from "axios";

const Collapse = ({ title, children, initial = false }) => {
  const [visible, setVisible] = useState(initial);

  const handleClick = React.useCallback(() => {
    setVisible((prev) => !prev);
  }, []);

  return (
    <li
      role="button"
      className="w-full flex items-center text-lg p-5 border-t border-b border-gray-200 hover:border-transparent rounded transition duration-300 ease-in-out bg-white hover:bg-gray-200"
      onClick={handleClick}
    >
      <div className="flex self-start justify-center items-center w-8 h-8 bg-white rounded-full shadow shrink-0 ml-3 mr-3">
        <svg
          className="w-6 h-6 fill-current text-helopro-primary"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
            clipRule="evenodd"
          />
        </svg>
      </div>
      <div>
        <p className="text-left font-semibold leading-snug tracking-tight">
          {title}
        </p>

        <div
          className={classNames("mt-2", {
            visible: visible,
            hidden: !visible,
          })}
        >
          <p className="text-sm font-normal text-gray-600" dangerouslySetInnerHTML={{ __html: children }} />
        </div>
      </div>
    </li>
  );
};

export default function CareerSectionSix() {
  const [listBoarding, setListBoarding] = React.useState([]);

  async function getBoarding() {
    const url = endpoint.onboarding;

    try {
      const response = await axios.get(url);
      setListBoarding(response.data.data);
    } catch (error) {
      console.error(error);
    }
  }

  React.useEffect(() => {
    getBoarding()
  }, [])

  return (
    <section>
      <div className="max-w-screen-xl mx-auto px-4 sm:px-6">
        <div className="pt-32 pb-12 md:pt-40 md:pb-20">
          <h1 className="h2 text-helopro-secondary text-center">
            {/* Proses <span className="italic">On-boarding</span> Menjadi{" "}
            <span className="italic">Freelancer</span> HeloPro */}
            Proses <i>on-boarding</i> Mitra
          </h1>
          <div className="mt-3 space-y-3">
            <p className="text-center text-xl">
              {/* Sebelum menjadi <span className="italic">freelancer</span>{" "}
              HeloPro, Anda harus mengikuti proses berikut. */}
              Berikut adalah proses yang harus Anda lalui sebagai mitra yang akan menjadi <i>virtual team</i> HeloPro.
            </p>
          </div>
        </div>

        <div className="flex flex-col lg:grid md:grid-cols-2 md:gap-3 mt-8">
          {/* Tabs items */}
          <div
            className="max-w-xl md:max-w-none md:w-full mx-auto mb-8 md:mb-0"
            data-aos="zoom-y-out"
          >
            <div className="md:pr-4 lg:pr-12 xl:pr-16">
              <img
                className="on-boarding-image md:max-w-none rounded"
                src={require("../images/karir_embed.jpeg").default}
                width="516"
                height="516"
                alt="Features bg"
              />
            </div>
          </div>

          <ul className="mb-8 md:mb-0">
            {listBoarding.map((item, index) => (
              <Collapse title={item?.title} key={index}>
                {item?.value}
              </Collapse>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
}
