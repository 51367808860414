import React, { useState, useRef, useEffect, useCallback } from "react";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import { Pagination } from "swiper";

import "swiper/swiper.scss"; // core Swiper
import "swiper/modules/navigation/navigation.scss"; // Navigation module
import "swiper/modules/pagination/pagination.scss";
import endpoint from "utils/endpoint";
import axios from "axios";

function CeritaTestmonials() {
  const [items, setItems] = useState([]);

  useEffect(() => {
    getTestimonials();
  }, []);

  async function getTestimonials() {
    const url = `${endpoint.testimony}`;
    try {
      const response = await axios.get(url);
      setItems(response.data.data);
    } catch (error) {
      console.log(error);
    }
  }

  const refCarousel = useRef(null);

  const prevSlide = useCallback(() => {
    // eslint-disable-next-line no-unused-expressions
    refCarousel.current?.swiper.slidePrev();
  }, [refCarousel]);

  const nextSlide = useCallback(() => {
    // eslint-disable-next-line no-unused-expressions
    refCarousel.current?.swiper.slideNext();
  }, [refCarousel]);

  return (
    <section className="relative" style={{ backgroundColor: "#0A2F66" }}>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20">
          {/* Section header */}
          <div className="mx-auto text-center pb-12 md:pb-10">
            <h2 className="h2 mb-4 text-white">
              Kata Mereka yang Telah Menggunakan HeloPro
            </h2>
            {/* <p className="text-xl text-white" data-aos="zoom-y-out">
              Kami senang mendengar para pemilik bisnis yang dapat bekerja lebih
              produktif dan mengembangkan bisnis dengan menggunakan HeloPro
            </p> */}
          </div>

          {/* Carousel area */}
          <div className="relative">
            <Swiper
              ref={refCarousel}
              pagination={{
                bulletClass: "swiper-pagination-bullet-overide",
                bulletActiveClass: "swiper-pagination-bullet-active-overide",
              }}
              className="flex flex-col-reverse"
              modules={[Pagination]}
              spaceBetween={5}
              slidesPerView={3}
              breakpoints={{
                320: {
                  slidesPerView: 1,
                },
                640: {
                  slidesPerView: 1,
                },
                1024: {
                  slidesPerView: 3,
                },
                1280: {
                  slidesPerView: 3,
                },
              }}
            >
              {items.map((items, index) => {
                return (
                  <SwiperSlide key={index}>
                    <div
                      className="px-6 py-4 space-y-3 my-8 mx-4 h-full"
                      style={{
                        filter:
                          "drop-shadow(0px 20px 20px rgba(0, 0, 0, 0.25))",
                        backgroundColor: "#FBFBFB",
                        borderRadius: 8,
                        height: 400,
                      }}
                    >
                      <header className="flex items-center">
                        <div className="rounded-sm w-20 h-20 mr-3" width="80" height="80">
                        <img
                          className="image-testimonial mr-3"
                          src={items?.thumbnail_link}
                          width="80"
                          height="80"
                          alt="Team member 02"
                        />
                        </div>
                        <div>
                          <p className="text-lg font-semibold text-helopro-secondary line-clamp-1">
                            {items?.name}
                          </p>
                          <p className="text-sm text-helopro-primary line-clamp-1">
                            {items?.role}
                          </p>
                        </div>
                      </header>
                      <hr className="divide-y" />
                      <footer className="pb-4">
                        <p
                          className="text-base text-gray-600"
                          dangerouslySetInnerHTML={{ __html: items?.content }}
                        ></p>
                      </footer>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
            <div className="absolute bottom-0 left-0 right-0 flex items-center justify-between z-10">
              <button
                onClick={prevSlide}
                className="flex justify-center items-center w-12 h-12 bg-white rounded-full shrink-0 ml-3 mr-3"
                style={{ boxShadow: "0px 4px 6px -1px rgba(25, 25, 25, 0.24)" }}
              >
                <svg
                  className="w-6 h-6 fill-current text-gray-600"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
              <button
                onClick={nextSlide}
                className="flex justify-center items-center w-12 h-12 bg-white rounded-full shrink-0 ml-3 mr-3"
                style={{ boxShadow: "0px 4px 6px -1px rgba(25, 25, 25, 0.24)" }}
              >
                <svg
                  className="w-6 h-6 fill-current text-gray-600"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CeritaTestmonials;
