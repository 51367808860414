import React from "react";

import Header from "../partials/Header";

import Footer from "../partials/Footer";
// import { ParallaxContext } from "context/parallax";
// import { Link } from "react-router-dom";
// import classNames from 'classnames';
import { Helmet } from "react-helmet-async";
import endpoint from "utils/endpoint";
import axios from "axios";
import Image2 from "../images/Banyak Freelancer Terb... - xframe-105320-edited.jpg";
import Image3 from "../images/Hero Image - xframe-5218-edit.jpg";
import Image4 from "../images/Kami Cukup Selektif Da... - pexels-photo-7653812-edited.jpg";

function Support() {
  // const generalPicture = useContext(ParallaxContext);
  // const [tab, setTab] = React.useState(1);

  const [assistant, setAssistant] = React.useState([]);

  const getVirtualAssistant = async () => {
    const url = endpoint.virtualAssistant;

    try {
      const response = await axios.get(url);
      setAssistant(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    getVirtualAssistant();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    setSelected((prev) => ({
      ...prev,
      name: assistant?.[0]?.name,
      about: assistant?.[0]?.about,
      img: assistant?.[0]?.thumbnail_link,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assistant]);

  const [value, setValue] = React.useState(true);

  const [priceOutput] = React.useState({
    plan1: {
      true: ["Reliability"],
      false: ["Education"],
    },
    plan2: {
      true: ["Assurance"],
      false: ["Experience"],
    },
    plan3: {
      true: ["Tangibles"],
      false: ["Communication"],
    },
    plan4: {
      true: ["Empathy"],
      false: ["Tech-savvy"],
    },
    plan5: {
      true: ["Responsiveness"],
      false: ["Related-skills"],
    },
  });

  const [priceDescription] = React.useState({
    plan1: {
      true: [
        `Kemampuan memberikan pelayanan yang dijanjikan secara konsisten, akurat dan tepat waktu.`,
      ],
      false: [
        `
      Memiliki Pendidikan
      setidaknya perguruan
      tinggi.`,
      ],
    },
    plan2: {
      true: [
        `Kemampuan mengaplikasikan keahlian untuk menumbuhkan rasa percaya dari klien.`,
      ],
      false: [
        `Memiliki minimal 3
      tahun pengalaman
      yang relevan di asisten
      kami.`,
      ],
    },
    plan3: {
      true: [
        `Kemampuan untuk bekerja <i>remote</i> dan memiliki peralatan penunjang yang memadai.`,
      ],
      false: [
        `Memiliki keterampilan
      komunikasi verbal dan
      tertulis yang kuat baik
      secara formal maupun
      kasual.`,
      ],
    },
    plan4: {
      true: [
        `Kemampuan dalam membina hubungan baik dengan klien sesuai jasa yang ditawarkan.`,
      ],
      false: [
        `Memiliki pengalaman
      menjalankan software
      termasuk Outlook,
      Gmail, Google Apps,
      dan Microsoft office,
      serta memiliki
      kemauan untuk
      mempelajari teknologi
      baru.`,
      ],
    },
    plan5: {
      true: [
        `Kemampuan dalam merespon permintaan klien secara cepat dan tepat.`,
      ],
      false: [
        `Memiliki kemampuan
      dan pengetahuan
      terkait dengan scope
      pekerjaan masing-
      masing`,
      ],
    },
  });

  const [selected, setSelected] = React.useState({
    name: "",
    about: "",
    img: null,
  });

  // const myUsers = [
  //   { name: 'shark', likes: 'ocean' },
  //   { name: 'turtle', likes: 'pond' },
  //   { name: 'otter', likes: 'fish biscuits' }
  // ]

  // const usersByLikes = myUsers.map(item => {
  //   const container = {};

  //   container[item.name] = item.likes;
  //   container.age = item.name.length * 10;

  //   return container;
  // })

  // console.log(usersByLikes);

  // const classNav = classNames(
  //   "flex px-4 py-2 rounded-sm text-sm font-semibold text-white justify-self-start",
  // );

  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      <Helmet>
        <title>Tentang Virtual Team Kami</title>
      </Helmet>
      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="grow">
        {/* Hero section */}
        <section className="bg-gradient-to-b">
          <div className="max-w-6xl mx-auto px-4 sm:px-6">
            <div className="pt-32 pb-12 md:pt-40 md:pb-20">
              <div className="max-w-3xl mx-auto text-center pb-12">
                <h1 className="h1 mb-4">Tentang Virtual Team HeloPro</h1>
                <p className="text-xl text-gray-600">
                  {/* HELO dari virtual assistant kami yang akan
                  memperkenalkan diri lebih lanjut. */}
                  Memperkenalkan para talenta profesional dari HeloPro yang akan
                  menjadi bagian dari tim yang akan membantu perkembangan bisnis
                  Anda.
                </p>
                <div className="pt-5 mt-3">
                  <a
                    className="btn text-white w-full mb-4 sm:w-auto sm:mb-0 btn-shadow"
                    style={{ background: "#43C097" }}
                    href="https://api.whatsapp.com/send?phone=6281210009495?text=Helo,%20HeloPro!"
                  >
                    Mulai Konsultasi
                  </a>
                </div>
              </div>

              <figure className="flex justify-center items-start">
                <img
                  className="rounded shadow-2xl"
                  src={Image3}
                  width="768"
                  height="432"
                  alt="About us"
                />
              </figure>

              {/* Tabs buttons */}
              <div className="md:grid md:grid-cols-2 gridColumn md:gap-3 mt-8 pt-8">
                {/* Tabs items */}
                <h3 className="h3 pb-5 flex xl:hidden order-1">
                  Pemilihan Selektif untuk Bisnis Anda
                </h3>

                <div className="mb-8 md:mb-0 order-3 xl:order-2">
                  <h3 className="h3 hidden xl:flex">
                    Pemilihan Selektif untuk Bisnis Anda
                  </h3>
                  <p className="text-xl text-gray-600 mb-8 mt-3">
                    {/* Super Virtual Assistant Anda akan bertindak seolah-olah mereka
                    adalah bagian dari bisnis Anda - tetapi akan bekerja dari jarak
                    jauh secara fleksibel. Mereka akan menangani tugas-tugas yang
                    membuang waktu Anda, hanya memberikan bantuan yang Anda butuhkan. */}
                    Virtual Team HeloPro dikurasi dengan baik menggunakan
                    standarisasi tinggi untuk membantu pekerjaan yang Anda
                    delegasikan.
                  </p>
                  <div
                    className={`w-full flex items-center text-lg p-5 rounded border transition duration-300 ease-in-out mb-3 bg-white shadow-md border-gray-200 cursor-defaul`}
                  >
                    <div className="flex justify-center items-center w-8 h-8 bg-white rounded-full shadow shrink-0 ml-3 mr-3">
                      <svg
                        className="w-3 h-3 fill-current"
                        viewBox="0 0 12 12"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{ color: "#43C097" }}
                      >
                        <path
                          d="M11.854.146a.5.5 0 00-.525-.116l-11 4a.5.5 0 00-.015.934l4.8 1.921 1.921 4.8A.5.5 0 007.5 12h.008a.5.5 0 00.462-.329l4-11a.5.5 0 00-.116-.525z"
                          fillRule="nonzero"
                        />
                      </svg>
                    </div>
                    <div>
                      <div className="text-left font-bold leading-snug tracking-tight mb-1">
                        Pemilihan Terstandarisasi Tinggi
                      </div>
                      <div className="text-left leading-snug tracking-tight mb-1">
                        {/* Kami memiliki standarisasi dalam memilih assistant untuk
                        setiap kebutuhan Anda, hal ini dilakukan demi
                        menciptakan kolaborasi yang produktif dan efisien. */}
                        HeloPro memiliki standarisasi khusus dalam memilih mitra
                        untuk menjadi <i>Virtual Business Team</i> Anda untuk
                        menciptakan kolaborasi produktif dan efisien.
                      </div>
                    </div>
                  </div>
                  <div
                    className={`w-full flex items-center text-lg p-5 rounded border transition duration-300 ease-in-out mb-3 bg-white shadow-md border-gray-200 cursor-default`}
                  >
                    <div className="flex justify-center items-center w-8 h-8 bg-white rounded-full shadow shrink-0 ml-3 mr-3">
                      <svg
                        className="w-3 h-3 fill-current"
                        viewBox="0 0 12 12"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{ color: "#43C097" }}
                      >
                        <path
                          d="M11.854.146a.5.5 0 00-.525-.116l-11 4a.5.5 0 00-.015.934l4.8 1.921 1.921 4.8A.5.5 0 007.5 12h.008a.5.5 0 00.462-.329l4-11a.5.5 0 00-.116-.525z"
                          fillRule="nonzero"
                        />
                      </svg>
                    </div>
                    <div>
                      <div className="text-left font-bold leading-snug tracking-tight mb-1">
                        Penilaian Cermat
                      </div>
                      <div className="text-left leading-snug tracking-tight mb-1">
                        {/* Untuk menemukan asisten terbaik, kami teliti dalam
                        memilih, menilai, mewawancarai, sampai menempatkan
                        standar sikap dan pengalaman yang cukup tinggi. */}
                        Untuk menemukan mitra terbaik, kami teliti proses kurasi
                        mulai dari wawancara hingga pengalaman untuk mendukung
                        kualitas <i>Virtual Business Team</i> Anda.
                      </div>
                    </div>
                  </div>
                  <div
                    className={`w-full flex items-center text-lg p-5 rounded border transition duration-300 ease-in-out mb-3 bg-white shadow-md border-gray-200 cursor-default`}
                  >
                    <div className="flex justify-center items-center w-8 h-8 bg-white rounded-full shadow shrink-0 ml-3 mr-3">
                      <svg
                        className="w-3 h-3 fill-current"
                        viewBox="0 0 12 12"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{ color: "#43C097" }}
                      >
                        <path
                          d="M11.854.146a.5.5 0 00-.525-.116l-11 4a.5.5 0 00-.015.934l4.8 1.921 1.921 4.8A.5.5 0 007.5 12h.008a.5.5 0 00.462-.329l4-11a.5.5 0 00-.116-.525z"
                          fillRule="nonzero"
                        />
                      </svg>
                    </div>
                    <div>
                      <div className="text-left font-bold leading-snug tracking-tight mb-1">
                        Mitra Jangka Panjang
                      </div>
                      <div className="text-left leading-snug tracking-tight mb-1">
                        {/* Kami bangga memiliki hubungan jangka panjang dengan
                        asisten kami, banyak dari mereka telah bersama kami
                        selama bertahun-tahun. */}
                        Seluruh mitra kami telah memiliki riwayat hubungan yang
                        memang sudah lama dibangun untuk berbagai jenis
                        pekerjaan.
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="max-w-xl md:max-w-none md:w-full mx-auto mb-8 xl:mb-0 order-2 xl:order-3"
                  data-aos="zoom-y-out"
                >
                  <div className="flex justify-end">
                    <img
                      className="md:max-w-none mx-auto rounded"
                      src={Image4}
                      width="513"
                      height="375"
                      alt="Features bg"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Contact section */}
        <section>
          <div className="mx-auto px-4 sm:px-6">
            <div className="pb-12">
              {/* Tabs buttons */}
              <div className="md:grid md:grid-cols-2 grid-cols-1 md:gap-3 mt-8 gridColumn pt-8">
                <div
                  className="max-w-xl md:max-w-none md:w-full mx-auto mb-8 md:mb-0"
                  data-aos="zoom-y-out"
                >
                  <h3 className="h3 pb-5 flex xl:hidden">
                    Selalu Berusaha Memberi yang Terbaik
                  </h3>

                  <div className="flex justify-center xl:justify-center xl:flex">
                    <img
                      className="md:max-w-none rounded"
                      src={Image2}
                      width="650"
                      height="576"
                      alt="Features bg"
                    />
                  </div>
                </div>

                {/* Tabs items */}
                <div className="xl:max-w-xl mb-8 md:mb-0">
                  <h3 className="h3 hidden xl:flex">
                    Selalu Berusaha Memberi yang Terbaik
                  </h3>
                  <p className="text-xl text-gray-600 mb-8 mt-12">
                    {/* Freelancer yang kami rekrut menjadi seorang assistant
                    untuk klien HeloPro sangat senang bekerja dengan
                    HeloPro karena mereka dapat menggunakan
                    keterampilan dan pengalaman mereka secara fleksibel,
                    terlebih lagi dapat bekerja dari rumah. */}
                    Berdasarkan data dan pengalaman kami, mitra yang kami rekrut
                    menjadi <i>Virtual Team</i> untuk klien HeloPro menyatakan
                    rasa puas karena mereka bisa menikmati pekerjaan mereka
                    dengan menggunakan keahlian, keterampilan dan pengalaman
                    mereka secara fleksibel.
                  </p>
                  <p className="text-xl text-gray-600 mb-8 mt-3">
                    {/* Mereka menghargai kemampuan untuk
                    menyeimbangkan pekerjaan mereka dengan kehidupan
                    keluarga. Sebagai imbalannya, mereka memotivasi diri
                    sendiri, bahagia dalam pekerjaan mereka dan bertekad
                    untuk membantu Anda mencapai lebih banyak. */}
                    Para mitra kami juga sangat menghargai keseimbangan waktu,
                    oleh karena itu mereka terus mengupayakan kemampuan
                    mengimbangkan waktu dan muatan pekerjaan untuk membantu Anda
                    mencapai lebih banyak.
                  </p>
                  <div className="pt-5 mt-3 flex justify-center xl:justify-start">
                    <a
                      className="btn text-white w-full mb-4 sm:w-auto sm:mb-0 btn-shadow"
                      style={{ background: "#43C097" }}
                      href="https://api.whatsapp.com/send?phone=6281210009495?text=Helo,%20HeloPro!"
                    >
                      Mulai Konsultasi
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-gradient-to-b from-white to-gray-100">
          <div
            className="mx-auto px-4 sm:px-6"
            style={{
              maxWidth: "1600px",
            }}
          >
            <div className="pt-32 pb-12 md:pt-40 md:pb-20">
              {/* Section header */}
              <div className="max-w-6xl mx-auto text-center pb-12">
                <h1 className="h1 mb-4" data-aos="zoom-y-out">
                  Aspek Standarisasi HeloPro
                </h1>
                <p
                  className="text-xl text-gray-600"
                  data-aos="zoom-y-out"
                  data-aos-delay="150"
                >
                  Secara garis besar kami memiliki 2 aspek besar yang harus ada
                  pada setiap assistant kami, yaitu:
                </p>
              </div>

              {/* Pricing tables */}
              <div>
                {/* Pricing toggle */}
                <div
                  className="flex justify-center max-w-xs m-auto mb-16"
                  data-aos="zoom-y-out"
                  data-aos-delay="300"
                >
                  <div className="relative flex w-full mx-6 p-1 bg-gray-200 rounded">
                    <span
                      className="absolute inset-0 m-1 pointer-events-none"
                      aria-hidden="true"
                    >
                      <span
                        className={`absolute inset-0 w-1/2 bg-white rounded shadow transform transition duration-150 ease-in-out ${
                          value ? "translate-x-0" : "translate-x-full"
                        }`}
                      ></span>
                    </span>
                    <button
                      className={`relative flex-1 text-sm font-medium p-1 transition duration-150 ease-in-out ${
                        !value && "text-gray-500"
                      } rounded`}
                      style={{
                        background: value ? "#43C097" : "#FFFFFF",
                        color: value ? "#FFFFFF" : "#7F7F7F",
                        border: "1px solid #43C097",
                        boxSizing: "border-box",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        setValue(true);
                      }}
                    >
                      Soft-skill
                    </button>
                    <button
                      className={`relative flex-1 text-sm font-medium p-1 transition duration-150 ease-in-out ${
                        value && "text-gray-500"
                      } rounded`}
                      style={{
                        background: !value ? "#092F66" : "#FFFFFF",
                        color: !value ? "#FFFFFF" : "#7F7F7F",
                        border: "1px solid #0A2F66",
                        boxSizing: "border-box",
                        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.15)",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        setValue(false);
                      }}
                    >
                      Hard-skill
                    </button>
                  </div>
                </div>

                <div className="max-w-sm md:max-w-none mx-auto grid gap-8 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 xl:gap-6 items-start auto-rows-fr">
                  {/* Pricing table 1 */}
                  <div
                    className="relative w-auto flex flex-col flex-1 h-full py-5 px-6 rounded bg-white shadow-xl"
                    data-aos="zoom-y-out"
                    data-aos-delay="450"
                  >
                    <div className="mb-4">
                      <div className="text-lg font-bold mb-1">
                        {priceOutput.plan1[value]}
                      </div>
                      <div className="inline-flex items-baseline mb-2"></div>
                      <div className="text-lg text-gray-800">
                        {priceDescription.plan1[value]}
                      </div>
                    </div>
                  </div>

                  {/* Pricing table 2 */}
                  <div
                    className="relative w-auto flex flex-col flex-1 h-full py-5 px-6 rounded bg-white shadow-xl"
                    data-aos="zoom-y-out"
                    data-aos-delay="450"
                  >
                    <div className="mb-4">
                      <div className="text-lg font-bold mb-1">
                        {priceOutput.plan2[value]}
                      </div>
                      <div className="inline-flex items-baseline mb-2"></div>
                      <div className="text-lg text-gray-800">
                        {priceDescription.plan2[value]}
                      </div>
                    </div>
                  </div>

                  {/* Pricing table 3 */}
                  <div
                    className="relative w-auto flex flex-col flex-1 h-full py-5 px-6 rounded bg-white shadow-xl"
                    data-aos="zoom-y-out"
                    data-aos-delay="450"
                  >
                    <div className="mb-4">
                      <div className="text-lg font-bold mb-1">
                        {priceOutput.plan3[value]}
                      </div>
                      <div className="inline-flex items-baseline mb-2"></div>
                      <div
                        className="text-lg text-gray-800"
                        dangerouslySetInnerHTML={{
                          __html: `${priceDescription.plan3[value]}`,
                        }}
                      ></div>
                    </div>
                  </div>

                  {/* Pricing table 4 */}
                  <div
                    className="relative w-auto flex flex-col flex-1 h-full py-5 px-6 rounded bg-white shadow-xl"
                    data-aos="zoom-y-out"
                    data-aos-delay="450"
                  >
                    <div className="mb-4">
                      <div className="text-lg font-bold mb-1">
                        {priceOutput.plan4[value]}
                      </div>
                      <div className="inline-flex items-baseline mb-2"></div>
                      <div className="text-lg text-gray-800">
                        {priceDescription.plan4[value]}
                      </div>
                    </div>
                  </div>

                  {/* Pricing table 5 */}
                  <div
                    className="relative w-auto flex flex-col flex-1 h-full py-5 px-6 rounded bg-white shadow-xl"
                    data-aos="zoom-y-out"
                    data-aos-delay="450"
                  >
                    <div className="mb-4">
                      <div className="text-lg font-bold mb-1">
                        {priceOutput.plan5[value]}
                      </div>
                      <div className="inline-flex items-baseline mb-2"></div>
                      <div className="text-lg text-gray-800">
                        {priceDescription.plan5[value]}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pb-20" style={{ background: "#43C097 " }}>
          <div className="max-w-6xl mx-auto px-4 sm:px-6">
            {/* Section header */}
            <div className="max-w-6xl mx-auto text-center py-12 pt-20">
              <h1 className="h1 mb-4 text-white" data-aos="zoom-y-out">
                <i>Virtual Business Team</i> Kami
              </h1>
            </div>

            <div className="pl-10 md:pl-0 w-full space-x-12 flex overflow-scroll no-scrollbar md:justify-center xl:justify-start">
              <ul className="pl-[6rem] md:pl-0 flex justify-center font-medium -mx-5 -my-1">
                {assistant.map((assist, index) => {
                  return (
                    <li className="mx-5 my-1 shrink-0" key={index}>
                      <button
                        onClick={() =>
                          setSelected((prev) => ({
                            ...prev,
                            name: assist?.name,
                            about: assist?.about,
                            img: assist?.thumbnail_link,
                          }))
                        }
                        className={`${
                          selected?.name === assist?.name
                            ? "text-white"
                            : "text-white"
                        } border-solid`}
                        style={{
                          borderColor:
                            selected?.name === assist?.name
                              ? "#235f90"
                              : "#FFFFFF",
                          borderBottomWidth: "4px",
                        }}
                      >
                        HELO {assist?.name}
                      </button>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>

          <div className="md:grid md:grid-cols-2 md:gap-3 mt-8 max-w-6xl gridColumn mx-auto px-4 sm:px-6 pb-8">
            {/* Tabs items */}
            <div
              className="max-w-xl md:max-w-none md:w-full mx-auto mb-8 md:mb-0"
              data-aos="zoom-y-out"
            >
              <div className="md:pr-4 lg:pr-12 xl:pr-16">
                {selected?.img && (
                  <img
                    className="md:max-w-xl xl:max-w-none w-[300px] h-[300px] md:w-[500px] md:h-[500px] mx-auto rounded object-cover"
                    src={selected?.img}
                    alt="Features bg"
                  />
                )}
              </div>
            </div>

            <div className="mb-8 md:mb-0">
              <h3 className="h3" style={{ color: "#0A2F66" }}>
                {selected?.name}
              </h3>
              <p
                dangerouslySetInnerHTML={{ __html: `${selected?.about}` }}
                className="text-xl text-white mb-8 mt-3"
              ></p>
            </div>
          </div>
        </section>
      </main>

      {/*  Site footer */}
      <Footer />
    </div>
  );
}

export default Support;
