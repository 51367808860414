import React from "react";
import Header from "../partials/Header";
import Footer from "../partials/Footer";
import { Link, useHistory } from "react-router-dom";
import endpoint from "utils/endpoint";
import axios from "axios";
import TestimonialsCarousel from "partials/CeritaTestmonials";
import classnames from "classnames";
import { Helmet } from "react-helmet-async";

export default function CeritaSuccess() {
  const history = useHistory();
  const [options, setOptions] = React.useState({
    page: 1,
    page_size: 9,
    tag: null,
    is_have_next: false,
  });
  const [listCerita, setListCerita] = React.useState([]);
  const [listPekerjaan, setListPekerjaan] = React.useState([]);

  async function getCerita({ page, page_size, tag } = options) {
    const url = endpoint.ceritaSuccess;

    try {
      const response = await axios.get(url, {
        params: {
          page,
          page_size,
          pekerjaan_id: tag,
        },
      });
      setListCerita(response.data.data);
      setOptions({
        page: response.data.current_page,
        page_size: response.data.per_page,
        tag,
        is_have_next: Boolean(response.data.next_page_url),
      });
    } catch (error) {
      console.error(error);
    }
  }

  async function getTipePekerjaan() {
    const url = endpoint.pekerjaan;

    try {
      const response = await axios.get(url);
      setListPekerjaan(response.data.data);
    } catch (error) {
      console.log(error);
    }
  }

  React.useEffect(() => {
    getCerita();
    getTipePekerjaan();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ceritaQuery = React.useMemo(
    () => new URLSearchParams(history.location.search),
    [history.location.search]
  );
  const tagQuery = React.useMemo(() => ceritaQuery.get("tag"), [ceritaQuery]);

  React.useEffect(() => {
    getCerita({
      page: options.page,
      page_size: options.page_size,
      tag: tagQuery,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagQuery]);

  const classNavPrev = classnames(
    "flex px-4 py-2 rounded-md text-sm font-semibold text-white justify-self-start btn-shadow",
    {
      'opacity-[40%]': options.page === 1,
    }
  );

  const classNavNext = classnames(
    "flex px-4 py-2 rounded-md text-sm font-semibold text-white justify-self-start btn-shadow",
    {
      'opacity-[40%]': !options.is_have_next,
    }
  );

  const handleClickTag = (link) => {
    setOptions(prev => ({...prev, page: 1}));
    history.replace(link);
  };

  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      <Helmet>
        <title>Cerita Sukses</title>
      </Helmet>
      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="grow">
        <section className="relative" id="cerita-sukses">
          <div className="max-w-6xl mx-auto px-4 sm:px-6">
            <div className="pt-32 pb-12 md:pt-40 md:pb-20">
              {/* Page header */}
              <div className="max-w-3xl pb-12 md:pb-20 text-center md:text-left">
                <h1 className="h1">Cerita Sukses</h1>
                <p className="text-xl text-gray-600">
                  Lihat bagaimana HeloPro telah membantu puluhan bisnis
                  berkembang dengan dukungan <i>Virtual Business Team</i> dalam berbagai bidang.
                </p>
              </div>

              {/* Section tags */}
              <div className="border-b w-full space-x-10 flex overflow-scroll no-scrollbar border-gray-300 pb-4 mb-12">
                <ul className="flex justify-center space-x-10 md:justify-start font-medium -mx-5 -my-1">
                  {[{ nama: "Semua" }, ...listPekerjaan].map((item, index) => {
                    const href = `/success_stories?tag=${item?.id}`;
                    const isActive = item?.id === Number(tagQuery);
                    const classText = classnames("hover:underline", {
                      "text-gray-800": !isActive,
                      "text-helopro-primary underline": isActive,
                    });

                    if (!item?.id) {
                      return (
                        <li className="mx-5 my-1" key={index}>
                          <button
                            className={classnames("hover:underline", {
                              "text-gray-800": Boolean(tagQuery),
                              "text-helopro-primary underline": !tagQuery,
                            })}
                            onClick={() => handleClickTag("/success_stories")}
                          >
                            {item?.nama}
                          </button>
                        </li>
                      );
                    }

                    return (
                      <li className="mx-5 my-1" key={item?.id}>
                        <button
                          className={classText}
                          onClick={() => handleClickTag(href)}
                        >
                          {item?.nama}
                        </button>
                      </li>
                    );
                  })}
                </ul>
              </div>

              {/* Articles list */}
              <div className="max-w-sm mx-auto md:max-w-none">
                {/* Articles container */}
                <div className="grid gap-12 md:grid-cols-3 md:gap-x-6 md:gap-y-8 items-start">
                  {listCerita.map((item, index) => {
                    const link = `/portofolio/${item.id}`;

                    return (
                      <article
                        key={index}
                        className="flex flex-col h-full"
                        data-aos="zoom-y-out"
                      >
                        <header>
                          <Link to={link} className="block mb-6">
                            <figure className="relative h-0 pb-9/16 overflow-hidden translate-z-0 rounded">
                              <img
                                className="absolute inset-0 w-full h-full object-cover transform scale-105 hover:-translate-y-1 transition duration-700 ease-out"
                                src={item?.thumbnail_link}
                                width="352"
                                height="198"
                                alt="News 01"
                              />
                            </figure>
                          </Link>
                          <div className="mb-3">
                            <ul className="flex flex-wrap text-xs font-medium -m-1">
                              {item?.tipe_projek?.map((item, index) => {
                                const classItem = classnames(
                                  "inline-flex text-center py-1 px-3 rounded-full transition duration-150 ease-in-out",
                                  {
                                    "bg-helopro-primary text-gray-100":
                                      index === 0,
                                    "bg-blue-100 text-gray-800": index > 0,
                                  }
                                );

                                return (
                                  <li className="m-1" key={item?.id}>
                                    <span
                                      className={classItem}
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {item?.nama}
                                    </span>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                          <h3 className="text-xl font-bold leading-snug tracking-tight mb-2">
                            <Link to={link} className="hover:underline">
                              {item?.judul}
                            </Link>
                          </h3>
                        </header>
                        <p className="text-gray-600 grow line-clamp-4">
                          {item?.konten?.replace(/(<([^>]+)>)/gi, "")}
                        </p>
                        <footer className="text-sm flex items-center mt-4">
                          <div className="flex shrink-0 mr-3">
                            <a className="relative" href="#0">
                              <span
                                className="absolute inset-0 -m-px"
                                aria-hidden="true"
                              >
                                <span className="absolute inset-0 -m-px bg-white rounded-full"></span>
                              </span>
                              <img
                                className="relative rounded-full image-cerita-sukses"
                                src={item?.client_thumbnail_link}
                                width="32"
                                height="32"
                                alt="Author 01"
                              />
                            </a>
                          </div>
                          <div>
                            <span className="text-gray-600">By </span>
                            <span className="font-medium">
                              {item?.client_nama}
                            </span>
                          </div>
                        </footer>
                      </article>
                    );
                  })}
                </div>
              </div>
              <div className="flex items-center justify-between py-8">
                <button
                  className={classNavPrev}
                  onClick={() => {
                    getCerita({
                      page: options.page - 1,
                      page_size: options.page_size,
                      tag: options.tag,
                    });
                    document.getElementById("cerita-sukses").scrollIntoView({
                      behavior: "smooth",
                    });
                  }}
                  style={{ backgroundColor: "#43C097" }}
                >
                  <svg 
                    fill="none"
                    stroke="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 19 10"
                    className="w-6 h-6 mr-3"
                  >
                    <path
                      d="M1 5L19 5M5 9L1 5L5 9ZM1 5L5 1L1 5Z" 
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    />
                  </svg>
                  Sebelumnya
                </button>
                <div></div>
                <button
                  className={classNavNext}
                  onClick={() => {
                    getCerita({
                      page: options.page + 1,
                      page_size: options.page_size,
                      tag: options.tag,
                    });
                    document.getElementById("cerita-sukses").scrollIntoView({
                      behavior: "smooth",
                    });
                  }}
                  style={{ backgroundColor: "#43C097" }}
                >
                  Selanjutnya
                  <svg
                    className="w-6 h-6 ml-3"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M17 8l4 4m0 0l-4 4m4-4H3"
                    ></path>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </section>

        <TestimonialsCarousel />
      </main>

      <Footer />
    </div>
  );
}
