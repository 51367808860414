import axios from "axios";
import React from "react";
import endpoint from "utils/endpoint";

function FeaturesTable() {
  const [feature, setFeature] = React.useState([]);

  async function getFeatures() {
    const url = endpoint.pekerjaan;

    try {
      const response = await axios.get(url);
      setFeature(response.data.data);
    } catch (error) {
      console.error(error);
    }
  }

  React.useEffect(() => {
    getFeatures();
  }, []);

  // console.log(feature);

  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-12 md:pt-20">
          {/* Section header */}
          <div className="pb-12">
            <h2 className="h2">Pekerjaan yang Kami Tangani</h2>
          </div>

          {/* Table */}
          <div className="overflow-x-auto block whitespace-pre md:whitespace-normal">
            <table className="table-auto w-full border-b border-gray-200">
              {/* Table header */}
              <thead>
                <tr className="text-base sm:text-lg">
                  {/* <th className="text-bold text-left pr-2 py-4 min-w-48"></th> */}
                  <th className="text-bold text-left py-4">
                    Program Helopro
                  </th>
                  <th className="text-bold text-left py-4 pl-10 md:pl-0">
                    Layanan
                  </th>
                  {/* <th className="text-bold text-center px-2 py-4">Agency</th>
                  <th className="text-bold text-center pl-2 py-4">Enterprise</th> */}
                </tr>
              </thead>
              {/* Table body */}
              <tbody>
                {feature.map((item, index) => {
                  // console.log({item})
                  return (
                    <tr className="border-t border-green-800" key={index}>
                      <td className="text-sm sm:text-base py-4">
                        <img alt="" src={item.image_link} className="font-medium w-32 lg:w-5/12 h-15" style={{ verticalAlign: 'middle', textAlign: 'center' }}/>
                        {/* ${(index === 2 || index === 3) ? "lg:w-[14rem]" : "lg:w-5/12"} */}
                        {/* <div className="text-gray-600">Lorem ipsum dolor sit amet, consectetur adipiscing elit</div> */}
                      </td>
                      <div className="flex flex-wrap max-w-[500px] mx-auto pl-10 md:pl-0">
                      {item?.details?.map((detail, index) => {
                        return (
                          <td
                            className="text-base p-2 text-center font-medium bg-helopro-grey rounded-lg"
                            style={{ margin: "5px", color: "#666666" }}
                            key={detail?.id}
                          >
                            {detail?.detail}
                          </td>
                        );
                      })}
                      </div>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FeaturesTable;
