import React from "react";

function CareerCta() {
  return (
    <section className="pt-32 pb-12 md:pt-40 md:pb-20 relative">
      <div
        className="absolute inset-0 md:mt-24 lg:mt-0 pointer-events-none"
        style={{ backgroundColor: "#F0F0F0", top: "60%" }}
        aria-hidden="true"
      ></div>
      <div className="max-w-screen-xl mx-auto px-4 sm:px-6">
        <div className="pb-12 md:pb-20">
          {/* CTA box */}
          <div
            className="rounded shadow-2xl overflow-hidden"
            data-aos="zoom-y-out"
            style={{ backgroundColor: "#092F66" }}
          >
            <div className="flex flex-col lg:flex-row justify-between items-center">
              {/* CTA content */}
              <div className="karir-cta-left mb-6 lg:mr-16 lg:mb-0 text-center lg:text-left py-10 px-8 md:py-10 md:px-12">
                <h3 className="h4 lg:h3 text-white mb-2">
                  {/* Apakah menjadi{" "}
                  <span className="italic">freelance virtual assistant</span>{" "}
                  tepat untuk Anda? */}
                  Bergabung menjadi Mitra <i>virtual team</i> HeloPro
                </h3>
                <p className="text-white text-base lg:text-lg opacity-75">
                  {/* Menjadi freelance{" "}
                  <span className="italic">virtual assistant</span> dan dapatkan
                  uang dengan melakukan pekerjaan dari rumah */}
                  Mari bekerja dan berkolaborasi bersama para pemilik bisnis Indonesia, sebagai ahli di bidang Anda masing-masing.
                </p>

                <div className="mt-5">
                  <a
                    className="btn-sm w-full md:w-auto text-white btn-shadow"
                    style={{ background: "#43C097" }}
                    href="https://wa.me/6285777771389?Text=Helo,%20HeloPro"
                  >
                    Daftar Sekarang
                  </a>
                </div>
              </div>

              {/* CTA button */}
              <div className="karir-cta-right">
                <img
                  src={require("../images/kurir_cta.jpg").default}
                  className="w-full h-full"
                  alt="cta-karir"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CareerCta;
