import { ParallaxContext } from "context/parallax";
import React from "react";
import { Link } from "react-router-dom";
import "index.css";

function FeaturesBlocks(props) {
  const generalPicture = React.useContext(ParallaxContext);

  const { data: detailResponse } = props;

  const data = React.useMemo(() => detailResponse, [detailResponse]);

  return (
    <section className="relative">
      <div className="relative mx-auto">
        <div className="py-12 md:py-8">
          {/* Section header */}
          <div className="mx-auto text-center max-w-screen-xl">
            <div className=" mx-auto px-4 sm:px-6 pt-12 md:pt-20">
              <div className="">
                {/* Section header */}
                <div className="max-w-none mx-auto text-center pb-12">
                  <h1
                    className="h1 mb-4"
                    style={{ color: "#092F66" }}
                    data-aos="zoom-y-out"
                  >
                    Lebih Efektif Bersama HeloPro
                  </h1>
                  <p
                    className="mx-auto text-xl text-gray-600"
                    data-aos="zoom-y-out"
                    data-aos-delay="150"
                    style={{
                      maxWidth: "44rem"
                    }}
                  >
                    {/* Kami akan menghubungkan anda dengan <i>virtual assistant</i>{" "}
                    kami yang kompeten dan telah terkurasi dengan baik. Anda
                    akan menghemat waktu dan biaya hingga hampir 50%
                    dibandingkan mempekerjakan <i>full-time assistant.</i> */}
                    <b>HeloPro</b> menghubungkan Anda dengan team yang kompeten dan terkurasi dengan baik. Hemat hingga 50% dibandingkan pegawai tetap.
                  </p>
                </div>

                {/* Pricing tables */}
                <div className="">
                  <div className="max-w-sm md:max-w-2xl xl:max-w-none mx-auto grid gap-2 md:grid-cols-2 xl:gap-6 items-start">
                    {/* Pricing table 1 */}
                    {data?.map((data, index) => {
                      const classDefault =
                        "relative flex flex-col h-full py-5 px-6 rounded bg-white shadow-xl";
                      const classBox =
                        data?.position === "right"
                          ? classDefault + " border-2 shadow-green"
                          : classDefault;

                      return (
                        <div
                          className={classBox}
                          data-aos="zoom-y-out"
                          data-aos-delay="450"
                          key={data?.id}
                        >
                          <div className="mb-4">
                            <div className="text-lg font-bold mb-1">
                              {data?.title}
                            </div>
                          </div>
                          {data?.list_content?.map((dataList, index) => (
                            <ul
                              key={index}
                              className="text-gray-600 -mb-2 grow"
                            >
                              <li className="flex text-left items-start mb-2">
                                {data?.position === "left" && (
                                  <svg
                                    className="w-3 h-3 fill-current text-gray-400 opacity-75 mr-3 shrink-0 mt-2"
                                    viewBox="0 0 12 12"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path d="M10.72 1.293a1 1 0 00-1.415 0L6.012 4.586 2.72 1.293a1 1 0 00-1.414 1.414L4.598 6 1.305 9.293a1 1 0 101.414 1.414l3.293-3.293 3.293 3.293a1 1 0 001.414-1.414L7.426 6l3.293-3.293a1 1 0 000-1.414z" />
                                  </svg>
                                )}
                                {data?.position === "right" && (
                                  <svg
                                    className="w-3 h-3 fill-current text-green-500 mr-3 shrink-0 mt-2"
                                    viewBox="0 0 12 12"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                                  </svg>
                                )}
                                <span>{dataList}</span>
                              </li>
                            </ul>
                          ))}
                        </div>
                      );
                    })}
                  </div>

                  <div
                    className="pt-5 w-full md:w-1/2 mt-6 self-center mx-auto"
                  >
                    <a
                      href="https://api.whatsapp.com/send?phone=6281210009495?text=Helo,%20HeloPro!"
                      className="btn-sm text-white w-full btn-shadow"
                      style={{ background: "#43C097" }}
                    >
                      Mulai Konsultasi
                    </a>
                  </div>
                  <div className="pb-12 pt-5 md:hidden" />
                </div>
              </div>
            </div>
          </div>

          {/* Section content */}
          <div className="pb-8 pt-20">
            {/* Section header */}
            <div className="max-w-none mx-auto text-center pb-12">
              <h1
                className="h1 mb-4"
                style={{ color: "#092F66" }}
                data-aos="zoom-y-out"
              >
                HeloPro Team dengan Berbagai Keahlian
              </h1>
              <p
                className="mx-auto px-6 text-xl text-gray-600"
                data-aos="zoom-y-out"
                data-aos-delay="150"
                style={{
                  maxWidth: "55rem"
                }}
              >
                Tidak perlu mencari satu per satu pegawai pada setiap pekerjaan yang ingin didelegasikan, cukup katakan “Helo” pada kami, kami akan hubungkan dengan ahlinya.
              </p>
            </div>
            <div
              className="flex flex-col items-center lg:flex-row"
              data-aos="zoom-y-out"
            >
              <div className="w-full md:w-1/2 flex flex-col text-center lg:text-left">
                <div className="marquee-container" style={{ height: "400px" }}>
                  <div className="marquee">
                    <img
                      src={generalPicture?.parallax?.[1]?.file_url}
                      alt="layanan"
                      className="w-auto h-full"
                    />
                  </div>
                  <div className="marquee">
                    <img
                      src={generalPicture?.parallax?.[1]?.file_url}
                      alt="layanan"
                      className="w-auto h-full"
                    />
                  </div>
                </div>
              </div>

              <div
                className="w-full mx-auto lg:w-1/2 text-white h-full lg:px-10 py-10 flex flex-row justify-center items-center text-center lg:text-left"
                style={{ backgroundColor: "#092F66", minHeight: 400 }}
              >
                <div className="katakan-hello-right text-center lg:text-left">
                  {/* <h3 className="h3 mb-3">HeloPro Siap Membantu</h3> */}
                  <p className="px-6 md:px-12 lg:px-0 text-xl text-white">
                    {/* Team HeloPro memiliki keterampilan dan pengalaman yang
                    diperlukan untuk menangani tugas-tugas yang tidak
                    menggunakan waktu Anda dengan baik. */}
                    Dengan berbagai keterampilan dan pengalaman yang sudah dikurasi, HeloPro team siap membantu Anda untuk lebih produktif.
                  </p>

                  <div className="pt-5">
                    <Link
                      to="/service"
                      className="btn-sm text-white w-auto btn-shadow"
                      style={{ background: "#43C097" }}
                    >
                      <span>Tugas yang kami kerjakan</span>
                      <svg
                        className="w-6 h-6 ml-3"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M17 8l4 4m0 0l-4 4m4-4H3"
                        ></path>
                      </svg>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FeaturesBlocks;
