import { ParallaxContext } from "context/parallax";
import React, { useContext } from "react";
import "index.css";

function HeroHome() {
  const parallax = useContext(ParallaxContext)

  return (
    <section className="relative bg-blackBlue-100 text-white">
      {/* Illustration behind hero content */}
      <div
        className="absolute left-1/2 transform -translate-x-1/2 bottom-0 pointer-events-none -z-1"
        aria-hidden="true"
      >
        <svg
          width="1360"
          height="578"
          viewBox="0 0 1360 578"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <linearGradient
              x1="50%"
              y1="0%"
              x2="50%"
              y2="100%"
              id="illustration-01"
            >
              <stop stopColor="#FFF" offset="0%" />
              <stop stopColor="#EAEAEA" offset="77.402%" />
              <stop stopColor="#DFDFDF" offset="100%" />
            </linearGradient>
          </defs>
          <g fill="url(#illustration-01)" fillRule="evenodd">
            <circle cx="1232" cy="128" r="128" />
            <circle cx="155" cy="443" r="64" />
          </g>
        </svg>
      </div>

      <div className="mx-auto px-4 sm:px-6" style={{ maxWidth: "1400px" }}>
        {/* Hero content */}
        <div className="pt-32 pb-12 md:pt-40 md:pb-20 max-w-sm md:max-w-4xl xl:max-w-none mx-auto grid gap-2 md:grid-cols-2 xl:gap-6">
          {/* Section header */}
          <div className="max-w-2xl mx-auto text-center pb-12 md:pb-16">
            <h1
              className="h2 md:h1 leading-tighter tracking-tighter mb-4"
              style={{
                fontWeight: "600"
              }}
              data-aos="zoom-y-out"
            >
              Virtual Business Team{" "}
              <span
                className="text-xl md:text-2xl block"
              >
                {/* Untuk Membantu Pemilik Bisnis dan Professional yang Sibuk */}
                Bayangkan Anda memiliki team untuk membantu tugas-tugas Anda tanpa perlu merekrut pegawai tetap.
              </span>
            </h1>
            <div className="max-w-3xl mx-auto">
              {/* <blockquote
                className="text-xl mb-8 border-white mt-8"
                style={{
                  marginLeft: "40px",
                  borderLeftWidth: "2px",
                  borderStyle: "solid",
                  borderColor: "rgb(102 102 102 / 1)",
                }}
                data-aos="zoom-y-out"
                data-aos-delay="150"
              >
                Ucapkan 'HELO' untuk mencapai lebih banyak, dan ucapkan selamat
                tinggal pada tugas dan stres yang membuang waktu.
              </blockquote> */}
              <div style={{padding: "15px 0 15px 0"}} />
              <div
                className="max-w-xs mx-auto sm:max-w-none sm:flex sm:justify-center"
                data-aos="zoom-y-out"
                data-aos-delay="300"
              >
                <div>
                  <a
                    className="btn text-white w-full mb-4 sm:w-auto sm:mb-0 btn-shadow"
                    href="https://api.whatsapp.com/send?phone=6281210009495?text=Helo,%20HeloPro!"
                    style={{ background: "#43C097" }}
                  >
                    Mulai Konsultasi
                  </a>
                </div>
                <div>
                  <a
                    className="btn text-white bg-blackBlue-100 w-full sm:w-auto sm:ml-4 btn-shadow-secondary border border-white border-solid"
                    href="#1"
                    style={{ background: "#092F66" }}
                  >
                    Pelajari Lebih Lanjut
                  </a>
                </div>
              </div>
            </div>
          </div>

          {/* Hero image */}
          <div>
            <div
              className="flex justify-center mb-8"
              data-aos="zoom-y-out"
              data-aos-delay="450"
            >
              <div className="flex flex-col justify-center">
                <iframe
                  className="mx-auto hero-home"
                  id="iframe"
                  width="500"
                  height="289"
                  src={parallax?.youtube}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HeroHome;
