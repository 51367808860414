import React from "react";
import Image2 from "../images/Karir - Anda Pantas Mendapatkan - pexels-photo-8101754.jpeg";

export default function CareerSectionTwo() {
  return (
    <section>
      <div className="px-0">
        <h1 className="h2 pt-32 pb-12 mt-8 text-helopro-secondary text-center">
          Bekerja Fleksibel, Untungnya Dobel
        </h1>
        <div className="md:grid md:grid-cols-2 grid-cols-1 md:gap-3 gridColumn pb-12 md:pb-20">
          <div className="flex flex-col items-center mx-auto mb-6 px-6 md:px-12 gridGrid">
            
            <div className="space-y-3">
              <p className="text-left text-xl">
                {/* Sebagai bagian dari virtual team HeloPro, Anda akan bekerja untuk
                pengusaha, pemimpin, dan pemilik usaha kecil, memberi mereka
                dukungan eksekutif penting dan dukungan administratif. */}
                Sebagai mitra dan bagian dari <i>Virtual Business Team</i> HeloPro, Anda akan bekerja untuk pengusaha, pemimpin dan pemilik bisnis dari berbagai bidang yang posisinya saling membutuhkan dengan Anda.
              </p>
              <p className="text-left text-xl">
                {/* Klien kami memiliki satu kesamaan–mereka sangat membutuhkan
                bantuan seorang assistant untuk menyelesaikan pekerjaan yang
                non-strategis yang menghabiskan banyak waktu, meningkatkan
                keseimbangan kerja/kehidupan mereka, dan mencapai lebih banyak. */}
                Dapatkan keuntungan bekerja secara fleksibel dari mana saja dengan mengatur waktu yang Anda butuhkan dan tetap dibayar untuk menyelesaikan pekerjaan yang klien delegasikan.
              </p>
              <p className="text-left text-xl">
                {/* HeloPro telah membantu ratusan pekerja freelancer untuk bergabung
                dengan virtual team HeloPro, bekerja paruh waktu dan fleksibel
                sesuai yang mereka inginkan. */}
                Ini waktunya Anda berkolaborasi lebih banyak dan ikut ambil bagian dari perkembangan bisnis di Indonesia.
              </p>
            </div>
          </div>

          <div
            className="max-w-xl md:max-w-none md:w-full flex flex-row justify-center xl:justify-end items-center mx-auto mb-8 md:mb-0"
            data-aos="zoom-y-out"
          >
            <div className="flex justify-center">
              <img
                className="md:max-w-none rounded"
                src={Image2}
                width="679"
                height="516"
                alt="Features bg"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
