import React from 'react';

import Header from '../partials/Header';
import HeroHome from '../partials/HeroHome';
import FeaturesHome from '../partials/FeaturesHome';
import FeaturesBlocks from '../partials/FeaturesBlocks';
import News from '../partials/News';
import Footer from '../partials/Footer';
import * as services from 'utils/services/home';
import { Helmet } from 'react-helmet-async';

function Home() {
  const [detailResponse, setResponse] = React.useState([]);

  React.useEffect(() => {
    services.getContent({}, (data) => setResponse(data.data));
  }, []);

  return (
    <div className="flex flex-col min-h-screen overflow-hidden">

      <Helmet>
        <title>Home</title>
        <link rel="icon" type="image/png" href="%PUBLIC_URL%/favicon.ico" sizes="16x16" />
      </Helmet>

      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="grow">

        {/*  Page sections */}
        <HeroHome />
        <FeaturesHome data={detailResponse?.delegasi} />
        <FeaturesBlocks data={detailResponse?.benefit} />
        <News data={detailResponse?.before_after} />

      </main>

      {/*  Site footer */}
      <Footer />

    </div>
  );
}

export default Home;