import React from "react";
import Header from "../partials/Header";
import Footer from "../partials/Footer";
import { useParams } from "react-router-dom";
import axios from 'axios'
import endpoint from 'utils/endpoint'
import dayjs from 'dayjs'

export default function PortofoloDetail() {
  const { portofolioId } = useParams()
  const [portofolio, setPortofolio] = React.useState(null);

  async function getPortofolio() {
    const url = endpoint.ceritaSuccess + '/' + portofolioId

    try {
      const response = await axios.get(url);
      setPortofolio(response.data.data);
    } catch (error) {
      console.error(error);
    }
  }

  React.useEffect(() => {
    getPortofolio()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portofolioId])

  const createdAt = React.useMemo(
    () => dayjs(portofolio?.created_at).format("DD MMMM YYYY"),
    [portofolio]
  );


  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      <Header />

      {/*  Page content */}
      <main className="grow">
        <section>
          <div className="max-w-6xl mx-auto px-4 sm:px-6">
            <div className="pt-32 pb-12 md:pt-40 md:pb-20">
              <div className="max-w-3xl mx-auto lg:max-w-none">
                <article>
                  {/* Article header */}
                  <header className="max-w-3xl mx-auto mb-20">
                    {/* Title */}
                    <h1 className="h1 text-center mb-4">
                      {portofolio?.judul}
                    </h1>
                  </header>

                  {/* Article content */}
                  <div
                    className="lg:flex lg:justify-between"
                    data-sticky-container
                  >
                    {/* Main content */}
                    <div>
                      {/* Article meta */}
                      <div className="flex items-center mb-6">
                        <div className="flex shrink-0 mr-3">
                          <a className="relative" href="#0">
                            <span
                              className="absolute inset-0 -m-px"
                              aria-hidden="true"
                            >
                              <span className="absolute inset-0 -m-px bg-white rounded-full"></span>
                            </span>
                            <img
                              className="relative rounded-full"
                              src={portofolio?.client_thumbnail_link}
                              width="32"
                              height="32"
                              alt="Author 04"
                            />
                          </a>
                        </div>
                        <div>
                          <span className="text-gray-600">By </span>
                          <span className="font-medium">
                            {portofolio?.client_name}
                          </span>
                          <span className="text-gray-600">
                            {" "}
                            · {createdAt}
                          </span>
                        </div>
                      </div>
                      <hr className="w-16 h-px pt-px bg-gray-200 border-0 mb-6" />

                      {/* Article body */}
                      <div className="text-lg text-gray-600" dangerouslySetInnerHTML={{ __html: portofolio?.konten }}>
                        
                      </div>
                    </div>
                  </div>

                  {/* Article footer */}
                </article>
              </div>
            </div>
          </div>
        </section>
      </main>
      {/*  Site footer */}
      <Footer />
    </div>
  );
}
