import React, { useEffect, useState } from "react";
import { Switch, Route, useLocation } from "react-router-dom";

import "./css/style.scss";

import AOS from "aos";
import Sticky from "sticky-js";

import Home from "./pages/Home";
import Pricing from "./pages/Pricing";
import BlogPost from "./pages/BlogPost";
import About from "./pages/About";
import Help from "./pages/Support";
import PageNotFound from "./pages/PageNotFound";
import ABP from "./pages/ABP";
import CeritaSuccess from "pages/CeritaSuccess";
import PortofoloDetail from "pages/PortofolioDetail";
import endpoint from "utils/endpoint";
import axios from "axios";
import { ParallaxContext } from "context/parallax";
import { Helmet } from 'react-helmet-async'
import Career from "pages/Career";

function App() {
  const location = useLocation();

  useEffect(() => {
    AOS.init({
      once: true,
      disable: "phone",
      duration: 700,
      easing: "ease-out-cubic",
    });
    // eslint-disable-next-line no-unused-vars
    const sticky = new Sticky("[data-sticky]");
  });

  useEffect(() => {
    document.querySelector("html").style.scrollBehavior = "auto";
    window.scroll({ top: 0 });
    document.querySelector("html").style.scrollBehavior = "";
  }, [location.pathname]); // triggered on route change

  const [parallax, setParallax] = useState(null);

  async function getParallax() {
    const url = endpoint.paralax;
    try {
      const response = await axios.get(url);
      setParallax(response.data.data);
    } catch (error) {
      console.error(error);
    }
  }

  React.useEffect(() => {
    getParallax();
  }, []);

  return (
    <>
      <Helmet
        titleTemplate="%s - HeloPro"
        defaultTitle="HeloPro"
      >
        <meta name="description" content="HeloPro" />
      </Helmet>
      <ParallaxContext.Provider value={parallax}>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/abp">
            <ABP />
          </Route>
          <Route path="/service">
            <Pricing />
          </Route>
          <Route path="/blog-post/:blogId">
            <BlogPost />
          </Route>
          <Route path="/success_stories">
            <CeritaSuccess />
          </Route>
          <Route path="/portofolio/:portofolioId">
            <PortofoloDetail />
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/virtual_team">
            <Help />
          </Route>
          <Route path="/karir">
            <Career />
          </Route>
          <Route path="*">
            <PageNotFound />
          </Route>
        </Switch>
      </ParallaxContext.Provider>
    </>
  );
}

export default App;
