import React, { useState } from "react";
import "../index.css";
// import ImageStrategy from "../images/HeloPro_tactical_strategic.png";
import "index.css";
import endpoint from "utils/endpoint";
import axios from "axios";
import { ParallaxContext } from "context/parallax";

function PricingTables() {
  const generalPicture = React.useContext(ParallaxContext);
  const [
    // workList, 
    setWorklist] = useState([]);

  async function getWorkList() {
    const url = endpoint.tipePekerjaan;

    try {
      const response = await axios.get(url);
      setWorklist(response.data.data);
    } catch (error) {
      console.error(error);
    }
  }

  React.useEffect(() => {
    getWorkList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="bg-gradient-to-b from-white to-gray-100 relative">
      {/* <div
        className="black-bg inset-0 absolute md:mt-24 lg:mt-0 pointer-events-none"
        aria-hidden="true"
        style={{ backgroundColor: "#092F66" }}
      ></div> */}

      <div className="mt-10 md:mt-0 pb-12 md:pt-20 md:pb-20">
        <div
          className="flex flex-col items-center lg:flex-row"
          data-aos="zoom-y-out"
        >
          <div
            className="w-full lg:w-1/2 text-white h-full lg:px-10 py-10 bg-helopro-secondary flex justify-center text-center lg:text-left items-center"
            style={{ minHeight: 400 }}
          >
            <div className="katakan-hello-right">
              <h3 className="h3 xl:h1 mb-3">HeloPro Siap Membantu</h3>
              <p className="text-xl text-white">
                {/* Jika itu membuang-buang waktu Anda atau Anda tidak suka
                melakukannya, kami siap mengurusnya. */}
                Jika pekerjaan itu membuang waktu Anda atau Anda tidak suka melakukannya, delegasikan saja kepada HeloPro.
              </p>

              <div className="pt-5 mt-6">
                <a
                  className="btn-sm text-white w-1/2 md:w-auto"
                  style={{ background: "#43C097" }}
                  href="https://api.whatsapp.com/send?phone=6281210009495?text=Helo,%20HeloPro!"
                >
                  Mulai Konsultasi
                </a>
              </div>
            </div>
          </div>

          <div className="w-full md:w-1/2 flex flex-col text-center lg:text-left">
            <div className="marquee-container" style={{ height: "400px" }}>
              <div className="marquee">
                <img
                  src={generalPicture?.parallax?.[1]?.file_url}
                  alt="layanan"
                  className="w-auto h-full"
                />
              </div>
              <div className="marquee">
                <img
                  src={generalPicture?.parallax?.[1]?.file_url}
                  alt="layanan"
                  className="w-auto h-full"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        {/* <div className="pt-32 pb-12 md:pt-40 md:pb-20">
          <div>
            <div className="relative">
              <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
                <div className="py-12 md:py-20">
                  Section header
                  <div className="max-w-6xl mx-auto text-center pb-12 md:pb-20 items-center flex flex-col">
                    <h2 className="h2 mb-4">
                      Kami membagi tipe pekerjaan menjadi 2, yaitu;
                    </h2>
                    <img alt="" src={ImageStrategy} className="pt-8" />
                  </div>

                  Items
                  <div className="max-w-sm mx-auto grid gap-6 md:grid-cols-2 items-start md:max-w-2xl lg:max-w-none">
                    {workList.map((item, index) => {
                      return (
                        <div
                          className="relative flex flex-col items-center p-6 bg-white rounded shadow-xl"
                          key={item?.id}
                        >
                          <h4 className="text-xl font-bold leading-snug tracking-tight mb-1">
                            {item?.nama}
                          </h4>
                          <p
                            className="text-gray-600 text-center py-3"
                            dangerouslySetInnerHTML={{
                              __html: item?.deskripsi,
                            }}
                          ></p>
                          <div className="w-full border-t border-gray-300 py-3 mt-2">
                            <p className="text-gray-600 text-center">
                              {item?.payment}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </section>
  );
}

export default PricingTables;
