export const base_url = "https://admin.helopro.id";
export const api = `${base_url}/api`;

const endpoint = {
  // Tipe Pekerjaan
  tipePekerjaan: `${api}/content/tipe-pekerjaan`,

  pekerjaan: `${api}/content/pekerjaan`,

  // Testimony
  testimony: `${api}/content/testimony?type=client`,

  // Testimony
  virtualAssistant: `${api}/content/virtual-assistant`,

  // Footer
  footer: `${api}/content/setting`,

  // Card
  cardVersus: `${api}/content/cards`,

  // Blog
  blog: `${api}/blog`,

  // Cerita Sukses
  ceritaSuccess: `${api}/cerita-sukses`,

  // Podcast
  podcast: `${api}/podcast`,

  // paralax
  paralax: `${api}/gambar-parallax`,

  // Karir
  testimonyKarir: `${api}/content/testimony?type=virtual_assistant`,

  onboarding: `${api}/content/get?key=proses_karir`,

  delegasi: `${api}/content/get?key=langkah_delegasi`,
};

export default endpoint;
