import React from "react";
import Image1 from "images/recruitment_5495003 1-4x.png";
import Image2 from "images/money_1743981 1-4x.png";
import Image3 from "images/time_5257326-4x.png";
import Image4 from "images/error_1242510-4x.png";
import Image5 from "images/Frame-4x.png";

export default function CareerSectionThree() {
  const content = [
    { desc: "Tidak perlu keluar dan mencari klien", img: Image1 },
    { desc: "Tidak perlu berinvestasi untuk pemasaran jasa", img: Image2 },
    { desc: "Jam dan waktu kerja fleksibel sesuai kebutuhan", img: Image3 },
    {
      desc: "Urusan administrasi, pembuatan faktur dan penagihan pembayaran diatur tim HeloPro",
      img: Image4,
    },
    {
      desc: "Memiliki akses tak terbatas ke dukungan, bantuan dan bimbingan dari tim HeloPro",
      img: Image5,
    },
    {
      desc: "Dapat berkolaborasi dengan talenta dari bidang lainnya dalam proyek besar",
      img: (
        <svg
          // className="w-12 h-12 mb-3"
          // width="56"
          // height="56"
          viewBox="0 0 56 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          {/* <circle cx="28" cy="28" r="28" fill="#35436A" /> */}
          <path
            d="M28 23.375C23.3476 23.375 19.5625 27.1601 19.5625 31.8125C19.5625 36.4649 23.3476 40.25 28 40.25C32.6524 40.25 36.4375 36.4649 36.4375 31.8125C36.4375 27.1601 32.6524 23.375 28 23.375ZM28 38.375C24.3814 38.375 21.4375 35.4311 21.4375 31.8125C21.4375 28.1939 24.3814 25.25 28 25.25C31.6186 25.25 34.5625 28.1939 34.5625 31.8125C34.5625 35.4311 31.6186 38.375 28 38.375Z"
            fill="white"
          />
          <path
            d="M37.375 15.75H34.4013C34.0143 14.6589 32.9722 13.875 31.75 13.875H30.6519C30.2649 12.7837 29.2222 12 28 12C26.7778 12 25.7351 12.7837 25.3481 13.875H24.25C23.0278 13.875 21.9858 14.6589 21.5988 15.75H18.625C17.0742 15.75 15.8125 17.0117 15.8125 18.5625V41.1875C15.8125 42.7383 17.0742 44 18.625 44H37.375C38.9258 44 40.1875 42.7383 40.1875 41.1875V18.5625C40.1875 17.0117 38.9258 15.75 37.375 15.75ZM23.3125 16.6875C23.3125 16.1706 23.7331 15.75 24.25 15.75H26.125C26.6427 15.75 27.0625 15.3303 27.0625 14.8125C27.0625 14.2956 27.4831 13.875 28 13.875C28.5169 13.875 28.9375 14.2956 28.9375 14.8125C28.9375 15.3303 29.3573 15.75 29.875 15.75H31.75C32.2669 15.75 32.6875 16.1706 32.6875 16.6875V17.625H23.3125V16.6875ZM38.3125 41.1875C38.3125 41.7044 37.8919 42.125 37.375 42.125H18.625C18.1081 42.125 17.6875 41.7044 17.6875 41.1875V18.5625C17.6875 18.0456 18.1081 17.625 18.625 17.625H21.4375V18.5625C21.4375 19.0802 21.8573 19.5 22.375 19.5H33.625C34.1427 19.5 34.5625 19.0802 34.5625 18.5625V17.625H37.375C37.8919 17.625 38.3125 18.0456 38.3125 18.5625V41.1875Z"
            fill="white"
          />
          <path
            d="M28 27.125C26.4492 27.125 25.1875 28.3867 25.1875 29.9375C25.1875 30.4553 25.6072 30.875 26.125 30.875C26.6428 30.875 27.0625 30.4553 27.0625 29.9375C27.0625 29.4206 27.4831 29 28 29C28.5169 29 28.9375 29.4206 28.9375 29.9375C28.9375 30.2352 28.8006 30.5089 28.5618 30.6881C27.623 31.3931 27.0625 32.5143 27.0625 33.6875C27.0625 34.2052 27.4822 34.625 28 34.625C28.5178 34.625 28.9375 34.2052 28.9375 33.6875C28.9375 33.1009 29.2179 32.5402 29.6877 32.1875C30.4025 31.6508 30.8125 30.8307 30.8125 29.9375C30.8125 28.3867 29.5508 27.125 28 27.125Z"
            fill="white"
          />
          <path
            d="M28 36.5C28.5178 36.5 28.9375 36.0803 28.9375 35.5625C28.9375 35.0447 28.5178 34.625 28 34.625C27.4822 34.625 27.0625 35.0447 27.0625 35.5625C27.0625 36.0803 27.4822 36.5 28 36.5Z"
            fill="white"
          />
        </svg>
      ),
    },
  ];

  return (
    <section style={{ backgroundColor: "#F0F0F0" }}>
      <div className="max-w-screen-xl mx-auto px-4 sm:px-6">
        <div className="pt-20 pb-12 md:pt-20 md:pb-[60px]">
          <h1 className="h2 text-helopro-secondary text-center">
            {/* Keuntungan Menjadi <span className="italic">Virtual Assistant</span>{" "}
            HeloPro */}
            Keuntungan Menjadi Mitra HeloPro
          </h1>
          <div className="mt-10 space-y-3">
            <p className="text-center text-xl">
              {/* Saat Anda bekerja melalui HeloPro, kami menangani hampir semuanya
              untuk Anda–membuat Anda bebas untuk fokus pada pekerjaan yang
              menghasilkan uang yang menguntungkan. */}
              Saat tergabung dalam <i>Virtual Business Team</i> dengan menjadi
              mitra HeloPro, tim akan mengatur semua proses administrasi dengan
              klien. Anda hanya perlu memenuhi persyaratan di bawah dan fokus
              pada pekerjaan yang didelegasikan.
            </p>
          </div>
        </div>

        <ul className="space-y-3">
          {content.map((items, index) => {
            return (
              <li
                className="w-full flex items-center text-lg pr-5 rounded border transition duration-300 ease-in-out bg-white shadow-md border-gray-200 cursor-default"
                key={index}
              >
                <div
                  className={`keuntungan flex self-center justify-center items-center w-20 h-5 bg-helopro-secondary shadow shrink-0 mr-3 ${
                    index === 4 ? "py-4" : ""
                  }`}
                >
                  {index !== 5 ? (
                    <img
                      src={items.img}
                      alt=""
                      className={`z-20 ${index === 4 ? "w-10" : "w-10"}`}
                    />
                  ) : (
                    items.img
                  )}
                </div>
                <div>
                  <div className="text-left font-normal leading-snug tracking-tight mb-1">
                    {items.desc}
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </section>
  );
}
