import axios from "axios";
import React from "react";
import { Link } from "react-router-dom";
import endpoints from "../utils/endpoint";

export default function ArtikelABP() {
  const [options, setOptions] = React.useState({
    page: 1,
    page_size: 3,
    is_have_next: false,
  });
  const [listArticle, setListArticle] = React.useState([]);

  React.useEffect(() => {
    getArticle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getArticle({ page = 1, page_size = 3 } = options) {
    const url = endpoints.blog;
    const config = {
      params: {
        page: page,
        page_size: 6,
      },
    };

    try {
      const response = await axios(url, config);
      setListArticle(response.data.data);
      setOptions({
        page: response.data.current_page,
        page_size: response.data.per_page,
        is_have_next: Boolean(response.data.next_page_url),
      });
    } catch (error) {
      console.log(error);
    }
  }

  const classDefault =
    "flex px-4 py-2 rounded-md text-sm font-semibold text-white justify-self-start btn-shadow";
  const classNavPrev =
    options.page === 1 ? classDefault + " opacity-[40%]" : classDefault;
  const classNavNext = !options.is_have_next
    ? classDefault + " opacity-[40%]"
    : classDefault;

  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20">
          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-10">
            <h2 className="h2">Artikel Inspiratif untuk Terus Mencapai Lebih</h2>
          </div>

          <div className="max-w-sm mx-auto md:max-w-none mt-10">
            <div className="grid gap-12 md:grid-cols-3 md:gap-x-6 md:gap-y-8 items-center text-center">
              {listArticle.map((item, index) => {
                const blogUrl = `/blog-post/${item.id}`;

                return (
                  <article
                    key={index}
                    className="flex flex-col h-full"
                    data-aos="zoom-y-out"
                  >
                    <header>
                      <Link to={blogUrl} className="block mb-6">
                        <figure className="relative h-0 pb-9/16 overflow-hidden translate-z-0 rounded">
                          <img
                            className="absolute inset-0 w-full h-full object-cover transform scale-105 hover:-translate-y-1 transition duration-700 ease-out"
                            src={item?.thumbnail_link}
                            width="352"
                            height="198"
                            alt="News 01"
                          />
                        </figure>
                      </Link>
                      <h3 className="text-xl text-left font-bold leading-snug tracking-tight">
                        <Link to={blogUrl} className="hover:underline">
                          “{item?.judul}”
                        </Link>
                      </h3>
                    </header>
                    <footer className="text-sm flex items-center mt-4">
                      <div className="flex shrink-0 mr-3">
                        <a className="relative" href="#0">
                          <span
                            className="absolute inset-0 -m-px"
                            aria-hidden="true"
                          >
                            <span className="absolute inset-0 -m-px bg-white rounded-full"></span>
                          </span>
                          <img
                            className="relative rounded-full"
                            src={item?.user?.avatar_link}
                            width="32"
                            height="32"
                            alt={item?.user?.name}
                          />
                        </a>
                      </div>
                      <div>
                        <span className="text-gray-600">By </span>
                        <span className="font-medium">{item?.user?.name}</span>
                      </div>
                    </footer>
                  </article>
                );
              })}
            </div>

            <div className="flex items-center justify-between py-8">
              <button
                className={classNavPrev}
                onClick={() => getArticle({ page: options.page - 1 })}
                style={{ backgroundColor: "#43C097" }}
              >
                <svg
                  fill="none"
                  stroke="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 19 10"
                  className="w-6 h-6 mr-3"
                >
                  <path
                    d="M1 5L19 5M5 9L1 5L5 9ZM1 5L5 1L1 5Z"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                </svg>
                Sebelumnya
              </button>
              <div></div>
              <button
                className={classNavNext}
                onClick={() => getArticle({ page: options.page + 1 })}
                style={{ backgroundColor: "#43C097" }}
              >
                Selanjutnya
                <svg
                  className="w-6 h-6 ml-3"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M17 8l4 4m0 0l-4 4m4-4H3"
                  ></path>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
