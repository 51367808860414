import classNames from "classnames";
import { ParallaxContext } from "context/parallax";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import "index.css";
import endpoint from "utils/endpoint";
import axios from "axios";

function News(props) {
  const parallax = useContext(ParallaxContext);
  const { data: detailResponse } = props;

  const data = React.useMemo(() => detailResponse, [detailResponse]);
  const [delegasi, setDelegasi] = React.useState([]);

  const getDelegasi = async () => {
    const url = endpoint.delegasi;

    try {
      const response = await axios.get(url);
      setDelegasi(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    getDelegasi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="sm:py-6 md:py-0">
          {/* Articles list */}
          <div className="max-w-sm mx-auto md:max-w-none mt-10 md:mt-0">
            <div className="grid gap-12 md:grid-cols-3 md:gap-x-6 md:gap-y-8 items-center text-center"></div>
          </div>
        </div>

        <div className="max-w-4xl mx-auto text-center pb-8 pt-20 mt-3">
          <h1 className="h2 mb-4 text-helopro-secondary">
            {/* Delegasikan dalam 3 langkah mudah */}
            Delegasi Pekerjaan dalam 3 Langkah Mudah
          </h1>
          <p className="text-xl text-gray-600">
            Tidak pernah semudah dan secepat ini untuk mendapatkan{" "}
            <i>virtual assitant </i>berpengalaman.
          </p>
        </div>

        <div className="relative max-w-sm mx-auto grid gap-6 md:grid-cols-2 lg:grid-cols-3 items-start md:max-w-2xl lg:max-w-none">
          <div
            className="absolute top-1/2 w-full h-1 hidden lg:block"
            aria-hidden="true"
            style={{ backgroundColor: "#43C097" }}
          ></div>
          {delegasi?.map((delegate) => (
            <div
              className="relative flex flex-col items-center p-6 bg-white rounded shadow-xl h-full"
              key={delegate?.id}
            >
              <div
                className="flex justify-center items-center h-12 w-12 rounded-full text-white font-bold mb-3"
                style={{ background: "#43C097" }}
              >
                {delegate?.id}
              </div>
              <h4 className="text-xl font-bold leading-snug tracking-tight mb-1">
                <i>{delegate?.title}</i>
              </h4>
              <ul className="text-gray-600 text-center">
                <li
                  dangerouslySetInnerHTML={{ __html: `${delegate?.value}` }}
                  className=""
                ></li>
              </ul>
            </div>
          ))}
          {/* 1st item */}
        </div>

        <div className="flex justify-center pb-4 md:pb-16 pt-8">
          <a
            href="https://wa.me/6281210009495?Text=Helo,%20HeloPro"
            className="btn-sm w-full md:w-1/2 text-white mt-6 btn-shadow"
            style={{ background: "#43C097" }}
          >
            <span>Konsultasi Sekarang</span>
          </a>
        </div>

        {/* Tabs buttons */}
        <div className="md:grid md:grid-cols-2 grid-cols-1 md:gap-3 mt-8 gridColumn">
          {/* Tabs items */}
          <div
            className="max-w-xl md:max-w-none md:w-full mx-auto mb-8 md:mb-0"
            data-aos="zoom-y-out"
          >
            <div className="md:pr-4 lg:pr-12 xl:pr-16">
              <img
                className="md:max-w-none mx-auto rounded"
                src={parallax?.parallax?.[0]?.file_url}
                width="500"
                height="375"
                alt="Features bg"
              />
            </div>
          </div>

          <div className="mb-8 md:mb-0">
            <h3 className="h3 text-helopro-secondary">
              Kolaborasi Efektif & Efisien
            </h3>
            <p className="text-xl text-gray-600 mb-8 mt-3">
              {/* <i>Super Virtual Assistant</i> Anda akan bertindak seolah-olah
              mereka adalah bagian dari bisnis Anda dengan bekerja dari jarak
              jauh secara fleksibel. Mereka akan menangani tugas-tugas yang
              membuang waktu Anda dan hanya memberikan bantuan yang Anda
              butuhkan. */}
              <i>Virtual Business Team</i>&ensp;HeloPro akan membantu
              meringankan pekerjaan yang Anda delegasikan dengan lebih efisien
              untuk mencapai target bisnis Anda.
            </p>
            <div className="flex items-center text-lg p-5 rounded border transition duration-300 ease-in-out mb-3 bg-white shadow-md border-gray-200 cursor-default">
              <div className="flex justify-center items-center w-8 h-8 bg-white rounded-full shadow shrink-0 ml-3 mr-3">
                <svg
                  className="w-3 h-3 fill-current"
                  viewBox="0 0 12 12"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ color: "#43C097" }}
                >
                  <path
                    d="M11.854.146a.5.5 0 00-.525-.116l-11 4a.5.5 0 00-.015.934l4.8 1.921 1.921 4.8A.5.5 0 007.5 12h.008a.5.5 0 00.462-.329l4-11a.5.5 0 00-.116-.525z"
                    fillRule="nonzero"
                  />
                </svg>
              </div>
              <div>
                <div className="text-left font-bold leading-snug tracking-tight mb-1">
                  {/* <i>Assistant</i> yang kompeten dan terkurasi dengan baik
                  sesuai kebutuhan */}
                  Kumpulan profesional yang kompeten & terkurasi sesuai
                  kebutuhan
                </div>
              </div>
            </div>
            <div className="w-full flex items-center text-lg p-5 rounded border transition duration-300 ease-in-out mb-3 bg-white shadow-md border-gray-200 cursor-default">
              <div className="flex self-end justify-center items-center w-8 h-8 bg-white rounded-full shadow shrink-0 ml-3 mr-3">
                <svg
                  className="w-3 h-3 fill-current"
                  viewBox="0 0 12 12"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ color: "#43C097" }}
                >
                  <path
                    d="M11.854.146a.5.5 0 00-.525-.116l-11 4a.5.5 0 00-.015.934l4.8 1.921 1.921 4.8A.5.5 0 007.5 12h.008a.5.5 0 00.462-.329l4-11a.5.5 0 00-.116-.525z"
                    fillRule="nonzero"
                  />
                </svg>
              </div>
              <div>
                <div className="text-left font-bold leading-snug tracking-tight mb-1">
                  Bekerja <i>part-time </i> dari rumah
                </div>
              </div>
            </div>
            <div className="flex items-center text-lg p-5 rounded border transition duration-300 ease-in-out mb-3 bg-white shadow-md border-gray-200 cursor-default">
              <div className="flex justify-center items-center w-8 h-8 bg-white rounded-full shadow shrink-0 ml-3 mr-3">
                <svg
                  className="w-3 h-3 fill-current"
                  viewBox="0 0 12 12"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ color: "#43C097" }}
                >
                  <path
                    d="M11.854.146a.5.5 0 00-.525-.116l-11 4a.5.5 0 00-.015.934l4.8 1.921 1.921 4.8A.5.5 0 007.5 12h.008a.5.5 0 00.462-.329l4-11a.5.5 0 00-.116-.525z"
                    fillRule="nonzero"
                  />
                </svg>
              </div>
              <div>
                <div className="text-left font-bold leading-snug tracking-tight mb-1">
                  Koordinasi melalui <i>platform</i> yang Anda familiar (
                  <i>Whatsapp, Zoom, Email,</i> dan lain sebagainya).
                </div>
              </div>
            </div>

            <div className="flex justify-center xl:justify-end">
              <Link
                to="/virtual_team"
                className="btn-sm text-white w-auto mt-6 btn-shadow"
                style={{ background: "#43C097" }}
              >
                <span>Tentang Virtual Business Team</span>
                <svg
                  className="w-6 h-6 ml-3"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M17 8l4 4m0 0l-4 4m4-4H3"
                  ></path>
                </svg>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="pt-12 md:pt-20">
        <div className="mx-auto text-center bg-helopro-secondary">
          <div className="max-w-6xl mx-auto px-4 sm:px-6 pt-12 md:pt-20 md:pb-16">
            <div className="">
              {/* Section header */}
              <div className="max-w-3xl mx-auto text-center pb-12">
                <h1 className="h1 mb-4 text-white" data-aos="zoom-y-out">
                  Keuntungan Menggunakan HeloPro
                </h1>
              </div>

              {/* Pricing tables */}
              <div className="">
                <div className="max-w-sm md:max-w-2xl xl:max-w-none mx-auto grid gap-2 md:grid-cols-2 xl:gap-6 items-start">
                  {/* Pricing table 1 */}
                  {data?.map((data, index) => {
                    const classDefault =
                      "relative flex flex-col h-full py-5 px-6 rounded bg-white";
                    const classBox =
                      data?.position === "right"
                        ? classDefault + " btn-shadow"
                        : classDefault + " shadow-xl";

                    return (
                      <div
                        className={classBox}
                        data-aos="zoom-y-out"
                        data-aos-delay="450"
                        key={data?.id}
                      >
                        <div className="mb-4">
                          <div className="text-lg font-bold mb-1">
                            {data?.title}
                          </div>
                        </div>
                        {data?.list_content?.map((dataList, index) => (
                          <ul key={index} className="text-gray-600 -mb-2 grow">
                            <li className="flex items-start mb-2">
                              {data?.position === "left" && (
                                <svg
                                  className="w-3 h-3 fill-current text-gray-400 opacity-75 mr-3 shrink-0 mt-2"
                                  viewBox="0 0 12 12"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M10.72 1.293a1 1 0 00-1.415 0L6.012 4.586 2.72 1.293a1 1 0 00-1.414 1.414L4.598 6 1.305 9.293a1 1 0 101.414 1.414l3.293-3.293 3.293 3.293a1 1 0 001.414-1.414L7.426 6l3.293-3.293a1 1 0 000-1.414z" />
                                </svg>
                              )}
                              {data?.position === "right" && (
                                <svg
                                  className="w-3 h-3 fill-current text-green-500 mr-3 shrink-0 mt-2"
                                  viewBox="0 0 12 12"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                                </svg>
                              )}
                              <span className={classNames("text-left")}>
                                {dataList}
                              </span>
                            </li>
                          </ul>
                        ))}
                      </div>
                    );
                  })}
                </div>

                <div className="pt-5 pb-12 md:pb-0 mt-6 self-center mx-auto">
                  <a
                    className="btn-sm w-full md:w-auto text-white btn-shadow"
                    style={{ background: "#43C097" }}
                    href="https://api.whatsapp.com/send?phone=6281210009495?text=Helo,%20HeloPro!"
                  >
                    Mulai Konsultasi
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default News;
