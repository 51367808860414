import React from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import endpoint from "../utils/endpoint";
import dayjs from "dayjs";
import { Helmet } from "react-helmet-async";

dayjs.locale("id");

function PostSingle() {
  const { blogId } = useParams();

  const [article, setArticle] = React.useState({});

  async function getBlogById() {
    const url = endpoint.blog + "/" + blogId;

    try {
      const response = await axios.get(url);
      setArticle(response.data.data);
    } catch (error) {
      console.log(error);
    }
  }

  React.useEffect(() => {
    getBlogById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blogId]);

  const createdAt = React.useMemo(
    () => dayjs(article.created_at).format("DD MMMM YYYY"),
    [article]
  );

  return (
    <section>
      
      <Helmet>
        <title>{article?.judul}</title>
      </Helmet>

      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-32 pb-12 md:pt-40 md:pb-20">
          <div className="max-w-3xl mx-auto lg:max-w-none">
            <article>
              {/* Article header */}
              <header className="max-w-3xl mx-auto mb-20">
                {/* Title */}
                <h1 className="h1 text-center mb-4">{article?.judul}</h1>
              </header>

              {/* Article content */}
              <div className="lg:flex lg:justify-between">
                {/* Main content */}
                <div>
                  {/* Article meta */}
                  <div className="flex items-center mb-6">
                    <div className="flex shrink-0 mr-3">
                      <a className="relative" href="#0">
                        <span
                          className="absolute inset-0 -m-px"
                          aria-hidden="true"
                        >
                          <span className="absolute inset-0 -m-px bg-white rounded-full"></span>
                        </span>
                        <img
                          className="relative rounded-full"
                          src={article?.user?.avatar_link}
                          width="32"
                          height="32"
                          alt="Author 04"
                        />
                      </a>
                    </div>
                    <div>
                      <span className="text-gray-600">By </span>
                      <span className="font-medium">{article?.user?.name}</span>
                      <span className="text-gray-600"> · {createdAt}</span>
                    </div>
                  </div>
                  <hr className="w-16 h-px pt-px bg-gray-200 border-0 mb-6" />

                  <figure className="mb-8">
                    <img
                      className="w-full rounded"
                      src={article?.thumbnail_link}
                      width="768"
                      height="432"
                      alt="Blog single"
                    />
                  </figure>
                  {/* Article body */}
                  <div
                    className="text-lg text-gray-600"
                    dangerouslySetInnerHTML={{ __html: article?.konten }}
                  >
                    {/* <div>
                      <hr className="w-full h-px pt-px mt-8 bg-gray-200 border-0" />
                      <div className="mt-8">
                        Interested in more tips like this? Check out <a className="text-gray-900 underline" href="#0">Introducing the Testing Field Guide</a>.
                                    </div>
                      <div className="mt-6">
                        <Link to="/blog" className="inline-flex items-center text-base text-blue-600 font-medium hover:underline">
                          <svg className="w-3 h-3 fill-current text-blue-400 shrink-0 mr-2" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                            <path d="M.293 5.282L5 .5l1.414 1.436-3 3.048H12v2.032H3.414l3 3.048L5 11.5.293 6.718a1.027 1.027 0 010-1.436z" />
                          </svg>
                          <span>Back to the blog</span>
                        </Link>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>

              {/* Article footer */}
            </article>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PostSingle;
