import React from "react";

export default function HeroABP() {
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-20 pb-12 md:py-28">
          {/* Section header */}
          <div className="max-w-6xl mx-auto text-center pb-12 md:pb-16">
            <h1 className="h2 mb-4 max-w-3xl mx-auto">
              Inspirasi Produktif Membangun Bisnis
            </h1>
            <p className="max-w-[50rem] mx-auto text-xl text-gray-600">
              {/* Dari artikel dan buku hingga kursus, Anda akan menemukan semua
              yang Anda butuhkan untuk mencapai lebih banyak dan memanfaatkan
              asisten Anda sebaik mungkin di sini. */}
              Temukan kumpulan artikel, buku hingga kursus untuk mendorong Anda terus berinovasi dan mencapai lebih bersama <i>Virtual Business Team</i> HeloPro.
            </p>
          </div>

          {/* World illustration */}
          <div className="max-w-3xl mx-auto pt-12 md:pt-16">
            <div className="relative">
              <img
                className="w-full h-auto"
                src={require("images/ABP - Hero Image - pexels-photo-8412316-edited.jpeg").default}
                width="400"
                height="400"
                alt="Planet"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
