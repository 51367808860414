import React, { useRef, useCallback } from "react";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import { Pagination } from "swiper";

import "swiper/swiper.scss"; // core Swiper
import "swiper/modules/navigation/navigation.scss"; // Navigation module
import "swiper/modules/pagination/pagination.scss";
import endpoint from "utils/endpoint";
import axios from "axios";

export default function CareerSectionFive() {
  const [listTestimony, setListTestimony] = React.useState([]);

  const refCarousel = useRef(null);

  const prevSlide = useCallback(() => {
    // eslint-disable-next-line no-unused-expressions
    refCarousel.current?.swiper.slidePrev();
  }, [refCarousel]);

  const nextSlide = useCallback(() => {
    // eslint-disable-next-line no-unused-expressions
    refCarousel.current?.swiper.slideNext();
  }, [refCarousel]);

  async function getTestimony() {
    const url = endpoint.testimonyKarir;

    try {
      const response = await axios.get(url);
      setListTestimony(response.data.data);
    } catch (error) {
      console.log(error);
    }
  }

  React.useEffect(() => {
    getTestimony();
  }, []);

  return (
    <section className="bg-white">
      <div className="max-w-screen-xl mx-auto px-4 sm:px-6">
        <div className="pt-32 pb-12 md:pt-40 md:pb-20">
          <h1 className="h2 text-helopro-secondary text-center">
            Apa Kata Mereka
          </h1>
          <div className="mt-5 space-y-3">
            <p className="text-center text-xl">Anda bisa bekerja dari rumah</p>
          </div>
        </div>

        <div className="relative">
          <Swiper
            ref={refCarousel}
            pagination={{
              bulletClass: "swiper-pagination-bullet-overide",
              bulletActiveClass: "swiper-pagination-bullet-active-overide",
            }}
            className="flex flex-col-reverse"
            modules={[Pagination]}
            spaceBetween={5}
            slidesPerView={3}
            breakpoints={{
              320: {
                slidesPerView: 1,
              },
              640: {
                slidesPerView: 1,
              },
              1024: {
                slidesPerView: 3,
              },
              1280: {
                slidesPerView: 3,
              },
            }}
          >
            {listTestimony.map((items, index) => {
              return (
                <SwiperSlide key={index}>
                  <div
                    className="px-6 py-4 space-y-3 my-8 mx-4"
                    style={{
                      filter: "drop-shadow(0px 20px 20px rgba(0, 0, 0, 0.25))",
                      backgroundColor: "#FBFBFB",
                      borderRadius: 8,
                      height: 400,
                    }}
                  >
                    <header className="flex items-center">
                      <img
                        className="image-testimonial mr-3"
                        src={items?.thumbnail_link}
                        width="80"
                        height="80"
                        alt="Team member 02"
                      />
                      <div>
                        <p className="text-lg font-semibold text-helopro-secondary line-clamp-1">
                          {items?.name}
                        </p>
                        <p className="text-sm text-helopro-primary line-clamp-1">
                          {items?.role}
                        </p>
                      </div>
                    </header>
                    <hr className="divide-y" />
                    <footer className="pb-4">
                      <p
                        className="text-base text-gray-600"
                        dangerouslySetInnerHTML={{ __html: items?.content }}
                      ></p>
                    </footer>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
          <div className="absolute bottom-[10px] left-0 right-0 flex items-center justify-between z-10">
            <button
              onClick={prevSlide}
              className="flex justify-center items-center w-12 h-12 bg-white rounded-full shrink-0 ml-3 mr-3"
              style={{ boxShadow: "0px 4px 6px -1px rgba(25, 25, 25, 0.24)" }}
            >
              <svg
                className="w-6 h-6 fill-current text-gray-600"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
            <button
              onClick={nextSlide}
              className="flex justify-center items-center w-12 h-12 bg-white rounded-full shrink-0 ml-3 mr-3"
              style={{ boxShadow: "0px 4px 6px -1px rgba(25, 25, 25, 0.24)" }}
            >
              <svg
                className="w-6 h-6 fill-current text-gray-600"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
        </div>

        <div className="w-full flex justify-center mt-5">
          <a
            className="btn-sm text-white w-full md:w-auto btn-shadow"
            style={{ background: "#43C097" }}
            href="https://wa.me/6285777771389?Text=Helo,%20HeloPro"
          >
            Daftar Sekarang
          </a>
        </div>
      </div>
    </section>
  );
}
