import React from "react";

export default function CareerSectionFour() {
  return (
    <section className="relative" style={{ backgroundColor: "#F0F0F0" }}>
      {/* Section background (needs .relative class on parent and next sibling elements) */}
      <div
        className="absolute inset-0 md:mt-24 lg:mt-0 pointer-events-none"
        style={{ backgroundColor: "#092F66", top: "60%" }}
        aria-hidden="true"
      ></div>
      <div className="absolute left-0 right-0 bottom-0 m-auto w-px p-px h-20 bg-gray-200 transform translate-y-1/2"></div>

      <div className="relative max-w-screen-xl mx-auto px-4 sm:px-6">
        <div className="py-12">
          {/* Section header */}
          <div className="pt-32 pb-12 md:pt-28 md:pb-20">
            <h1 className="h2 text-helopro-secondary text-center">
              {/* Persyaratan Menjadi <span className="italic">Virtual Assistant</span> HeloPro */}
              Mudah jadi <i>Virtual Team</i> HeloPro
            </h1>
            <div className="mt-10 space-y-3">
              <p className="text-center text-xl">
                {/* Saat Anda bekerja melalui HeloPro, kami menangani hampir
                semuanya untuk Anda–membuat Anda bebas untuk fokus pada
                pekerjaan yang menghasilkan uang yang menguntungkan. */}
                Hanya dengan memenuhi beberapa ketentuan, Anda bisa mendaftar
                serta mendapat keuntungan sebagai mitra dan tergabung dalam{" "}
                <i>Virtual Business Team</i> HeloPro.
              </p>
            </div>
          </div>

          {/* Items */}
          <div className="max-w-sm mx-auto grid gap-6 md:grid-cols-2 lg:grid-cols-6 items-start md:max-w-2xl lg:max-w-none">
            {/* 1st item */}
            <div className="relative flex flex-col items-center p-6 bg-white rounded shadow-xl h-full lg:col-span-2">
              <svg
                className="w-16 h-16 p-1 -mt-1 mb-2"
                width="56"
                height="56"
                viewBox="0 0 56 56"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="28" cy="28" r="28" fill="#57BD98" />
                <g clip-path="url(#clip0_1481_39391)">
                  <path
                    d="M18.2604 22.7853C18.4664 22.8882 19.0499 23.1972 19.6874 22.4812C19.9424 22.1233 20.1974 21.7653 20.5014 21.4122C20.8594 21.0052 20.8055 20.3432 20.3984 19.9852C19.9914 19.6272 19.3294 19.6812 18.9715 20.0882C18.6135 20.4952 18.2555 20.9562 17.9515 21.4122C17.6523 21.8683 17.8044 22.4763 18.2604 22.7853Z"
                    fill="#FBFBFB"
                  />
                  <path
                    d="M28.2102 17.9894C29.1811 17.9894 30.1472 18.1415 31.0691 18.4504C31.6869 18.6318 32.1381 18.2444 32.344 17.8374C32.4961 17.2784 32.192 16.7145 31.682 16.5624C30.5591 16.2045 29.4361 16.0034 28.2641 16.0034C27.7051 16.0034 27.2441 16.4104 27.2441 16.9744C27.2441 17.5383 27.6512 17.9894 28.2102 17.9894Z"
                    fill="#FBFBFB"
                  />
                  <path
                    d="M37.2921 24.2124C37.6501 25.0804 37.8512 25.9974 37.9542 26.9144C37.9542 26.9144 38.013 28.0521 39.0771 27.8853C40.1314 27.748 39.9941 26.7133 39.9941 26.7133C39.8912 25.5904 39.5871 24.4674 39.1801 23.4474C38.9251 22.9374 38.3661 22.6824 37.8561 22.8884C37.3412 23.1434 37.0862 23.7024 37.2921 24.2124Z"
                    fill="#FBFBFB"
                  />
                  <path
                    d="M33.6192 19.7253C34.4333 20.2843 35.1492 20.9512 35.7622 21.7162C36.2182 22.285 36.9881 22.0497 37.1401 21.8682C37.5471 21.5102 37.6501 20.8482 37.2922 20.4412C36.5762 19.5733 35.7082 18.7592 34.7422 18.0923C34.2812 17.7883 33.6732 17.8864 33.3642 18.3473C33.0553 18.8083 33.1583 19.4163 33.6192 19.7253Z"
                    fill="#FBFBFB"
                  />
                  <path
                    d="M22.6008 19.5685C23.4148 19.0585 24.3367 18.6515 25.2537 18.3965C25.8128 18.2445 26.1217 17.6806 25.9697 17.1706C25.8177 16.6115 25.2537 16.3026 24.7437 16.4546C23.6208 16.7587 22.5518 17.2196 21.5808 17.8326C21.1199 18.1366 20.9678 18.7496 21.2768 19.2105C21.4778 19.5195 21.9094 19.8235 22.6008 19.5685Z"
                    fill="#FBFBFB"
                  />
                  <path
                    d="M38.9741 29.1603C38.4151 29.0083 37.9051 29.3663 37.8021 29.9253C37.6992 30.3863 37.3853 31.4602 37.3804 31.5632C36.0319 35.0694 32.7954 37.536 29.0784 37.9332C25.4201 38.3157 21.86 36.6631 19.7955 33.6914L21.6835 34.1082C22.1935 34.2112 22.7525 33.9023 22.8555 33.3432C22.9585 32.8332 22.6495 32.2742 22.0905 32.1712L18.1135 31.3033C17.8585 31.2542 17.0641 31.3033 16.8876 32.0683L16.0196 36.0992C15.9167 36.6091 16.2256 37.1682 16.7846 37.2712C17.4074 37.3349 17.8536 36.9671 18.0106 36.4571L18.3146 35.0743C20.585 38.1489 24.6846 40.4243 29.2843 39.929C33.9233 39.4288 37.8561 36.3051 39.3861 31.9211C39.4351 31.7936 39.6656 30.7246 39.744 30.3372C39.896 29.7733 39.5381 29.2633 38.9741 29.1603Z"
                    fill="#FBFBFB"
                  />
                  <path
                    d="M24.0717 29.0082H28.7744C29.3335 29.0082 29.7454 28.5473 29.7944 27.9883V21.0151C29.7944 20.4561 29.3335 19.9951 28.7744 19.9951C28.2154 19.9951 27.7545 20.4561 27.7545 21.0151V26.9683H24.0717C23.5127 26.9683 23.0518 27.4292 23.0518 27.9883C23.0518 28.5473 23.5078 29.0082 24.0717 29.0082Z"
                    fill="#FBFBFB"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1481_39391">
                    <rect
                      width="24"
                      height="24"
                      fill="white"
                      transform="translate(16 16)"
                    />
                  </clipPath>
                </defs>
              </svg>

              <h4 className="text-xl font-bold leading-snug tracking-tight mb-1 text-black">
                Jam Terbang Cukup
              </h4>
              <p className="text-gray-600 text-center">
                Minimal 3 tahun pengalaman di bidang yang relevan.
              </p>
            </div>

            {/* 2nd item */}
            <div className="relative flex flex-col items-center p-6 bg-white rounded shadow-xl h-full lg:col-span-2">
              <svg
                className="w-16 h-16 p-1 -mt-1 mb-2"
                width="56"
                height="56"
                viewBox="0 0 56 56"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="28" cy="28" r="28" fill="#57BD98" />
                <g clip-path="url(#clip0_1481_39405)">
                  <path
                    d="M42.7231 26.603L28.6053 13.9784C28.5806 13.9537 28.5559 13.9352 28.5251 13.9167C28.2166 13.6205 27.7229 13.6082 27.3959 13.8982L13.2781 26.5227C12.9326 26.8313 12.9079 27.3557 13.2103 27.7013C13.3769 27.8864 13.6052 27.9789 13.8335 27.9789C14.0309 27.9789 14.2284 27.9111 14.3888 27.7692L16.931 25.4923V25.9366V32.5265V40.2147C16.931 41.3686 17.8689 42.3127 19.0289 42.3127C19.0474 42.3127 19.0598 42.3127 19.0783 42.3127C19.0968 42.3127 19.1091 42.3127 19.1276 42.3127H23.4839C24.5699 42.3127 25.4522 41.4303 25.4522 40.3443V32.8597C25.4522 32.6931 25.588 32.5573 25.7546 32.5573H30.2528C30.4194 32.5573 30.5551 32.6931 30.5551 32.8597V40.3443C30.5551 41.4303 31.4375 42.3127 32.5235 42.3127H36.9785C38.1508 42.3127 39.0764 41.1588 39.0764 39.6841V32.8227V30.7247V25.5725L41.6186 27.8494C41.779 27.9913 41.9765 28.0592 42.1739 28.0592C42.4022 28.0592 42.6305 27.9666 42.7971 27.7815C43.0933 27.436 43.0624 26.9115 42.7231 26.603ZM37.4042 30.7186V32.8165V39.6717C37.4042 40.2703 37.108 40.6343 36.9723 40.6343H32.5173C32.3507 40.6343 32.215 40.4986 32.215 40.332V32.8597C32.215 31.7737 31.3326 30.8913 30.2466 30.8913H25.7484C24.6624 30.8913 23.7801 31.7737 23.7801 32.8597V40.3443C23.7801 40.5109 23.6443 40.6467 23.4777 40.6467H19.1215C19.103 40.6467 19.0906 40.6467 19.0721 40.6467C19.0536 40.6467 19.0412 40.6467 19.0227 40.6467C18.7883 40.6467 18.5908 40.4554 18.5908 40.2147V32.5203V25.9304V23.9991L27.9574 15.6321L37.4042 24.0793V30.7186Z"
                    fill="#FBFBFB"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1481_39405">
                    <rect
                      width="30"
                      height="30"
                      fill="white"
                      transform="translate(13 13)"
                    />
                  </clipPath>
                </defs>
              </svg>

              <h4 className="text-xl font-bold leading-snug tracking-tight mb-1 text-black">
                Lingkungan Kerja Fleksibel
              </h4>
              <p className="text-gray-600 text-center">
                Memiliki pengalaman bekerja <i>remote</i>.
              </p>
            </div>

            {/* 3rd item */}
            <div className="relative flex flex-col items-center p-6 bg-white rounded shadow-xl h-full lg:col-span-2">
              <svg
                className="w-16 h-16 p-1 -mt-1 mb-2"
                width="56"
                height="56"
                viewBox="0 0 56 56"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="28" cy="28" r="28" fill="#57BD98" />
                <path
                  d="M40.66 21.7724H32.9999V18.8176C32.9999 17.5274 31.9502 16.4778 30.6601 16.4778H15.3398C14.0497 16.4778 13 17.5274 13 18.8176V27.9445C13 29.2347 14.0497 30.2843 15.3398 30.2843H15.5898L14.6836 31.782C14.2949 32.4245 14.4074 33.2668 14.9511 33.7848C15.2524 34.0719 15.6476 34.2299 16.0638 34.2299C16.3858 34.2299 16.6973 34.1349 16.9644 33.9549L22.416 30.2843H23V33.2392C23 34.5293 24.0497 35.579 25.3398 35.579H33.584L39.035 39.249C39.3068 39.4322 39.6211 39.522 39.9342 39.522C40.339 39.522 40.7419 39.3719 41.0481 39.0801C41.5926 38.5625 41.7055 37.7199 41.3164 37.0767L40.4103 35.579H40.6602C41.9504 35.579 43 34.5293 43 33.2392V24.1123C42.9998 22.8221 41.9502 21.7724 40.66 21.7724ZM21.8331 28.3751L17.0129 31.6205L18.9766 28.375H15.3398C15.1024 28.375 14.9093 28.1819 14.9093 27.9444V18.8176C14.9093 18.5802 15.1024 18.3871 15.3398 18.3871H30.6601C30.8975 18.3871 31.0906 18.5802 31.0906 18.8176V27.9445C31.0906 28.1819 30.8975 28.3751 30.6601 28.3751H21.8331ZM40.66 33.6697H37.0233L38.9869 36.9151L34.1668 33.6697H25.3397C25.1024 33.6697 24.9092 33.4766 24.9092 33.2392V30.2843H30.6601C31.9502 30.2843 32.9999 29.2347 32.9999 27.9445V23.6818H40.66C40.8975 23.6818 41.0905 23.8749 41.0905 24.1123V33.2392C41.0905 33.4766 40.8975 33.6697 40.66 33.6697Z"
                  fill="white"
                />
              </svg>

              <h4 className="text-xl font-bold leading-snug tracking-tight mb-1 text-black">
                Ketersediaan Waktu
              </h4>
              <p className="text-gray-600 text-center">
                Bersedia menerima delegasi pekerjaan selama hari kerja.
              </p>
            </div>

            {/* 4th item */}
            <div className="relative flex flex-col items-center p-6 bg-white rounded shadow-xl  lg:col-span-2  lg:col-start-2">
              <svg
                className="w-16 h-16 p-1 -mt-1 mb-2"
                width="56"
                height="56"
                viewBox="0 0 56 56"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="28" cy="28" r="28" fill="#57BD98" />
                <g clip-path="url(#clip0_1481_39431)">
                  <path
                    d="M28.093 14.9907C22.7634 14.9907 18.25 19.5298 18.25 24.9069C18.25 30.5375 23.4813 36.2957 27.0002 40.3427C27.0136 40.359 27.5824 40.9907 28.2832 40.9907H28.3453C29.0461 40.9907 29.6108 40.359 29.625 40.3427C32.9274 36.5463 37.75 30.2868 37.75 24.9069C37.75 19.5298 34.2294 14.9907 28.093 14.9907ZM28.4188 39.2528C28.3904 39.2812 28.3489 39.3129 28.3124 39.3393C28.275 39.3137 28.2344 39.2812 28.2043 39.2528L27.7794 38.7641C24.4433 34.9368 19.875 29.6958 19.875 24.9069C19.875 20.4125 23.6385 16.6153 28.093 16.6153C33.6416 16.6153 36.125 20.7794 36.125 24.9069C36.125 28.5424 33.5319 33.3695 28.4188 39.2528ZM28.0284 19.9108C25.3362 19.9108 23.1534 22.0936 23.1534 24.7858C23.1534 27.478 25.3362 29.6608 28.0284 29.6608C30.7206 29.6608 32.9034 27.478 32.9034 24.7858C32.9034 22.0936 30.7206 19.9108 28.0284 19.9108ZM28.0284 28.0358C26.2364 28.0358 24.741 26.5424 24.741 24.7501C24.741 22.9581 26.199 21.5001 27.991 21.5001C29.7842 21.5001 31.241 22.9581 31.241 24.7501C31.2418 26.5424 29.8216 28.0358 28.0284 28.0358V28.0358Z"
                    fill="#FBFBFB"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1481_39431">
                    <rect
                      width="26"
                      height="26"
                      fill="white"
                      transform="translate(15 15)"
                    />
                  </clipPath>
                </defs>
              </svg>

              <h4 className="text-xl font-bold leading-snug tracking-tight mb-1 text-black">
                Domisili
              </h4>
              <p className="text-gray-600 text-center">
                Tinggal dan bekerja dari berbagai penjuru di Indonesia.
                <br />
                <br />
              </p>
            </div>

            {/* 5th item */}
            <div className="relative flex flex-col items-center p-6 bg-white rounded shadow-xl h-full lg:col-span-2">
              <svg
                className="w-16 h-16 p-1 -mt-1 mb-2"
                width="56"
                height="56"
                viewBox="0 0 56 56"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="28" cy="28" r="28" fill="#57BD98" />
                <path
                  d="M37.825 33.3813H30.7188C30.2937 33.3813 29.95 33.7251 29.95 34.1501L29.9438 34.2938L26.0375 34.2876V34.1501C26.0375 33.7251 25.6938 33.3813 25.2688 33.3813H18.175C17.75 33.3813 17.4062 33.7251 17.4062 34.1501C17.4062 34.5751 17.75 34.9188 18.175 34.9188H24.6438C24.8875 35.4501 25.425 35.8251 26.0437 35.8251H29.9438C30.5688 35.8251 31.1063 35.4501 31.3438 34.9188H37.8188C38.2438 34.9188 38.5875 34.5751 38.5875 34.1501C38.5937 33.7251 38.25 33.3813 37.825 33.3813Z"
                  fill="#FBFBFB"
                />
                <path
                  d="M42.5125 33.3813H41.0625V19.4626C41.0625 19.4376 41.0625 19.4126 41.0562 19.3876C40.95 18.2875 40.0562 17.4563 38.9875 17.4563H16.9375C15.8312 17.4563 14.9313 18.3563 14.9313 19.4626V33.3813H13.4875C13.0625 33.3813 12.7188 33.7251 12.7188 34.1501V35.6001C12.7188 37.2251 14.0375 38.5438 15.6625 38.5438H40.325C41.95 38.5438 43.2687 37.2251 43.2687 35.6001V34.1501C43.2812 33.7251 42.9375 33.3813 42.5125 33.3813ZM41.75 35.6001C41.75 36.3813 41.1125 37.0126 40.3375 37.0126H15.6688C14.8875 37.0126 14.25 36.3751 14.25 35.6001V34.9188H15.6937C16.1187 34.9188 16.4625 34.5751 16.4625 34.1501V19.4626C16.4625 19.2001 16.675 18.9876 16.9375 18.9876H38.9875C39.2562 18.9876 39.4875 19.2126 39.5312 19.5063V34.1438C39.5312 34.5688 39.8812 34.9126 40.3 34.9126H41.75V35.6001Z"
                  fill="#FBFBFB"
                />
              </svg>

              <h4 className="text-xl font-bold leading-snug tracking-tight mb-1 text-black">
                Peralatan Lengkap
              </h4>
              <p className="text-gray-600 text-center">
                Memiliki laptop dan perlengkapan yang menunjang.
                <br />
                <br />
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
