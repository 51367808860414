import React from "react";

export default function CareerHero() {
  return (
    <section style={{ backgroundColor: "#092F66" }}>
      <div className="max-w-screen-xl mx-auto px-4 sm:px-6">
        <div className="pt-32 pb-12 md:pt-40 md:pb-20">
          <div>
            <div
              className="max-w-xl md:max-w-none md:gap-6 md:w-full mx-auto mb-8 md:mb-0 flex flex-col lg:flex-row justify-evenly"
              data-aos="zoom-y-out"
            >
              <div className="md:px-4 lg:px-12 xl:px-16 mb-8">
                <h3 className="h3 mb-3 text-white">
                  {/* Anda Dapat Memiliki Pekerjaan, Memiliki Hidup, dan Menikmati
                  Keduanya */}
                  Siap Jadi Mitra HeloPro?
                </h3>
                <p className="text-xl text-white">
                  {/* Jadi freelance virtual team HeloPro dan dapatkan uang dengan
                  melakukan pekerjaan menarik yang sesuai dengan Anda, hidup
                  Anda, dan keluarga Anda. */}
                  Waktunya bergabung menjadi mitra pekerja lepas dan bagian dari{" "}
                  <i>virtual team</i> HeloPro untuk bekerja sesuai bidang yang
                  Anda kuasai.
                </p>

                <div className="pt-5 mt-6 flex justify-center lg:justify-start">
                  <a
                    className="btn-sm w-full md:w-auto text-white btn-shadow"
                    style={{ background: "#43C097" }}
                    href="https://wa.me/6285777771389?Text=Helo,%20HeloPro"
                  >
                    Daftar Sekarang
                  </a>
                </div>
              </div>

              <div className="relative flex flex-col w-full text-center lg:text-left">
                <img
                  src={
                    require("../images/Karir - Hero Image  - pexels-photo-4474036.jpeg")
                      .default
                  }
                  alt="layanan"
                  className="w-auto h-auto rounded-lg shadow-lg"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
