import Header from "../partials/Header";
import Footer from "../partials/Footer";
import HeroABP from "../partials/HeroABP";
import ArtikelABP from "../partials/ArtikelABP";
import PodcastABP from "../partials/PodcastABP";
import { Helmet } from "react-helmet-async";

export default function ABP() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      <Helmet>
        <title>Article | Blog | Podcast</title>
      </Helmet>
      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="grow">
        <HeroABP />
        <ArtikelABP />
        <PodcastABP />
      </main>

      <Footer />
    </div>
  );
}
