import React from "react";

import Header from "../partials/Header";
import PricingTables from "../partials/PricingTables";
import FeaturesTable from "../partials/FeaturesTable";
import Cta from "../partials/Cta";
import Footer from "../partials/Footer";
import { Helmet } from "react-helmet-async";

function Pricing() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      <Helmet>
        <title>Layanan</title>
      </Helmet>
      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="grow">
        {/*  Page sections */}
        <PricingTables />
        <FeaturesTable />
        <Cta />
      </main>

      {/*  Site footer */}
      <Footer />
    </div>
  );
}

export default Pricing;
