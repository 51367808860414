import React from "react";

function FeaturesHome(props) {
  const { data: detailResponse } = props;

  const data = React.useMemo(() => detailResponse, [detailResponse]);

  return (
    <section className="relative" id="1">
      {/* Section background (needs .relative class on parent and next sibling elements) */}
      

      <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-12 md:pt-20">
          {/* Section header */}
          

          {/* Section content */}
          <div className="">
            {/* Content */}
            <div
              className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6 md:mt-6"
              data-aos="fade-right"
            >
              
            </div>

            <div className="max-w-6xl mx-auto px-4 sm:px-6">
              <div className="">
                {/* Section header */}
                <div className="max-w-3xl mx-auto text-center pb-12">
                  <h1 className="h1 mb-4" style={{color: "#092F66"}} data-aos="zoom-y-out">
                    Delegasikan Pekerjaan Anda!
                  </h1>
                  <p
                    className="text-lg text-gray-600"
                    data-aos="zoom-y-out"
                    data-aos-delay="150"
                  >
                    {/* Pemilik bisnis menghabiskan 80% waktunya untuk pekerjaan non-strategis. Ini memakan cukup waktu sedangkan pemilik bisnis perlu lebih fokus pada aspek strategis. */}
                    Banyak pemilik bisnis yang menghabiskan 80% waktunya untuk pekerjaan non-strategis yang tidak produktif. Delegasikan sekarang, dan fokus pada aspek strategis untuk bisnis lebih berkembang.
                  </p>
                </div>

                {/* Pricing tables */}
                <div>
                  <div className="max-w-sm md:max-w-2xl xl:max-w-none mx-auto grid gap-8 md:grid-cols-2 xl:gap-6 items-start">
                    {/* Pricing table 1 */}
                    {data?.map((data, index) => {
                      const classDefault =
                        "relative flex flex-col h-full py-5 px-6 rounded bg-white shadow-xl";
                      const classBox =
                        data?.position === "right"
                          ? classDefault + " border-2 shadow-green"
                          : classDefault;

                      return (
                        <div
                          className={classBox}
                          data-aos="zoom-y-out"
                          data-aos-delay="450"
                          key={data?.id}
                        >
                          <div className="mb-4">
                            <div className="text-lg font-bold mb-1">
                              {data?.title}
                            </div>
                          </div>
                          {data?.list_content?.map((dataList, index) => (
                            <ul
                              key={index}
                              className="text-gray-600 -mb-2 grow"
                            >
                              <li className="flex items-start mb-2">
                                {data?.position === "left" && (
                                  <svg
                                    className="w-3 h-3 fill-current text-gray-400 opacity-75 mr-3 shrink-0 mt-2"
                                    viewBox="0 0 12 12"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path d="M10.72 1.293a1 1 0 00-1.415 0L6.012 4.586 2.72 1.293a1 1 0 00-1.414 1.414L4.598 6 1.305 9.293a1 1 0 101.414 1.414l3.293-3.293 3.293 3.293a1 1 0 001.414-1.414L7.426 6l3.293-3.293a1 1 0 000-1.414z" />
                                  </svg>
                                )}
                                {data?.position === "right" && (
                                  <svg
                                    className="w-3 h-3 fill-current text-green-500 mr-3 shrink-0 mt-2"
                                    viewBox="0 0 12 12"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                                  </svg>
                                )}
                                <span>
                                  {dataList}
                                </span>
                              </li>
                            </ul>
                          ))}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FeaturesHome;
