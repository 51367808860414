import axios from "axios";
import React from "react";
import endpoint from "../utils/endpoint";
import PlayPodcast from "./PlayPodcast";

function BlogList() {
  const [options, setOptions] = React.useState({
    page: 1,
    page_size: 6,
    is_have_next: false,
  });
  const [listPodcats, setListPodcats] = React.useState([]);
  const [currentPodcast, setCurrentPodcast] = React.useState(null);

  React.useEffect(() => {
    getPodcats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getPodcats({ page = 1, page_size = 6 } = options) {
    const url = endpoint.podcast;
    const config = {
      params: {
        page: page,
        page_size: 6,
      },
    };

    try {
      const response = await axios(url, config);
      setListPodcats(response.data.data);
      setOptions({
        page: response.data.current_page,
        page_size: response.data.per_page,
        is_have_next: Boolean(response.data.next_page_url),
      });
    } catch (error) {
      console.log(error);
    }
  }

  const classDefault =
    "flex px-4 py-2 rounded-md text-sm font-semibold text-white justify-self-start btn-shadow";
  const classNavPrev =
    options.page === 1 ? classDefault + " opacity-[40%]" : classDefault;
  const classNavNext = !options.is_have_next
    ? classDefault + " opacity-[40%]"
    : classDefault;

  function scrollToTop() {
    document.getElementById("section-podcast").scrollIntoView({
      behavior: "smooth",
    });
  }

  return (
    <section id="section-podcast">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-32 pb-12 md:pt-40 md:pb-20">
          {/* Page header */}
          <div className="max-w-3xl pb-12 md:pb-20 text-center md:text-left">
            <h1 className="h1 mb-4">Podcast Produktivitas</h1>
            <p className="text-xl text-gray-600">
              {/* Kursus berbentuk siniar (podcast) kami mengulas berbagai macam
              topik yang akan memberikan anda perspektif baru untuk mencapai
              lebih banyak. */}
              Dengarkan berbagai podcast inspiratif yang mengulas berbagai topik menarik seputar bisnis.
            </p>
          </div>

          {/* Main content */}
          <div className="lg:flex lg:justify-between">
            {/* Articles container */}
            <div className="md:grow -mt-4">
              {listPodcats.map((item, index) => {
                const classDefault = "relative items-center shrink-0 mr-3";
                const classButtonPlay =
                  item?.id === currentPodcast?.id
                    ? "hidden"
                    : classDefault + " flex";

                return (
                  <article
                    key={index}
                    className="flex items-center py-4 border-b border-gray-200"
                  >
                    <div>
                      <header>
                        <h2 className="h4 mb-2">{item?.title}</h2>
                      </header>
                      <div
                        className="text-lg text-gray-600 mb-4"
                        dangerouslySetInnerHTML={{ __html: item?.description }}
                      />
                      <footer className="text-sm">
                        <div className="flex items-center">
                          <div className="flex shrink-0">
                            {Boolean(item?.audio_link) && (
                              <button
                                className={classButtonPlay}
                                onClick={() => setCurrentPodcast(item)}
                              >
                                <svg
                                  className="w-8 h-8 mr-2 text-red-500"
                                  fill="currentColor"
                                  viewBox="0 0 20 20"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                                <span className="font-medium">
                                  Play this episode
                                </span>
                              </button>
                            )}
                            {Boolean(item?.spotify) && (
                              <a
                                href={item?.spotify}
                                target="_blank"
                                rel="noreferrer"
                                className="flex items-center"
                              >
                                <span className="text-xs lg:text-xl font-semibold mr-2">
                                  Listen on
                                </span>
                                <img
                                  alt="play-on-spotify"
                                  className="h-4 w-15 lg:h-8 lg:w-auto"
                                  src={require("../images/spotify.png").default}
                                />
                              </a>
                            )}
                            {Boolean(item?.youtube) && (
                              <a
                                href={item?.youtube}
                                target="_blank"
                                rel="noreferrer"
                                className="flex items-center"
                              >
                                <span className="text-xs lg:text-xl font-semibold mr-2">
                                  Listen on
                                </span>
                                <img
                                  alt="play-on-youtube"
                                  className="h-4 w-13 lg:h-8 lg:w-auto"
                                  src={
                                    require("../images/yt_logo_rgb_light.png")
                                      .default
                                  }
                                />
                              </a>
                            )}
                          </div>
                        </div>
                      </footer>
                    </div>
                  </article>
                );
              })}

              <div className="flex items-center justify-between py-8">
                <button
                  className={classNavPrev}
                  style={{ backgroundColor: "#43C097" }}
                  onClick={() => {
                    getPodcats({ page: options.page - 1 });
                    scrollToTop();
                  }}
                >
                  <svg
                    fill="none"
                    stroke="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 19 10"
                    className="w-6 h-6 mr-3"
                  >
                    <path
                      d="M1 5L19 5M5 9L1 5L5 9ZM1 5L5 1L1 5Z"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    />
                  </svg>
                  Sebelumnya
                </button>
                <div></div>
                <button
                  className={classNavNext}
                  style={{ backgroundColor: "#43C097" }}
                  onClick={() => {
                    getPodcats({ page: options.page + 1 });
                    scrollToTop();
                  }}
                >
                  Selanjutnya
                  <svg
                    className="w-6 h-6 ml-3"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M17 8l4 4m0 0l-4 4m4-4H3"
                    ></path>
                  </svg>
                </button>
              </div>
            </div>

            {/* Sidebar */}
            <aside className="relative mt-12 md:mt-0 md:w-64 md:ml-12 lg:ml-20 md:shrink-0"></aside>
          </div>
        </div>
      </div>

      <PlayPodcast currentPodcast={currentPodcast} />
    </section>
  );
}

export default BlogList;
