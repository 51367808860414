import React from "react";

export default function PlayPodcast(props) {
  const { currentPodcast } = props;
  const [isPlaying, setIsPlaying] = React.useState(false);
  const [percentage, setPercentage] = React.useState(0);
  // eslint-disable-next-line no-unused-vars
  const [duration, setDuration] = React.useState(0);
  const [currentTime, setCurrentTime] = React.useState(0);

  const classDefault = "bg-white fixed bottom-0 left-0 right-0 shadow-2xl z-10";
  const classContainer = Boolean(currentPodcast)
    ? classDefault
    : classDefault + " hidden";

  const audioRef = React.useRef(null);
  const rangeRef = React.useRef();

  React.useEffect(() => {
    if (currentPodcast) {
      handlePlay()
      audioRef.current.currentTime = 0
    }
  }, [currentPodcast]);

  const handlePlay = () => {
    setIsPlaying(true);
    audioRef.current?.play();
  };

  const handlePause = () => {
    setIsPlaying(false);
    audioRef.current.pause();
  };

  const onChange = (e) => {
    const audio = audioRef.current;
    audio.currentTime = (audio.duration / 100) * e.target.value;
    setPercentage(e.target.value);
  };

  const getCurrDuration = (e) => {
    const percent = (
      (e.currentTarget.currentTime / e.currentTarget.duration) *
      100
    ).toFixed(2);
    const time = e.currentTarget.currentTime;

    setPercentage(+percent);
    setCurrentTime(time.toFixed(2));
  };

  function secondsToHms(seconds) {
    if (!seconds) return '00:00'

    let duration = seconds
    let hours = duration / 3600
    duration = duration % 3600

    let min = parseInt(duration / 60)
    duration = duration % 60

    let sec = parseInt(duration)

    if (sec < 10) {
      sec = `0${sec}`
    }
    if (min < 10) {
      min = `0${min}`
    }

    if (parseInt(hours, 10) > 0) {
      return `${parseInt(hours, 10)}:${min}:${sec}`
    } else if (min === 0) {
      return `00:${sec}`
    } else {
      return `${min}:${sec}`
    }
  }

  return (
    <div
      className={classContainer}
      style={{
        boxShadow: "rgb(0 0 0 / 20%) 0px -15px 40px 0px",
      }}
    >
      <div className="max-w-6xl mx-auto py-4 px-4 sm:px-6">
        <p className="text-xl font-semibold">{currentPodcast?.title}</p>
        <div className="py-2">
          <div className="relative w-full bg-gray-200 h-1">
            <div
              className="bg-blue-600 h-1 transition-all duration-200 ease-linear"
              style={{ width: `${percentage}%` }}
            ></div>

            <audio
              ref={audioRef}
              src={currentPodcast?.audio_link}
              onTimeUpdate={getCurrDuration}
              onLoadedData={(e) => {
                setDuration(e.currentTarget.duration.toFixed(2));
              }}
            ></audio>
            <input
              type="range"
              value={percentage}
              ref={rangeRef}
              step="0.01"
              className="range-audio"
              onChange={onChange}
            />
          </div>
          <p>{secondsToHms(currentTime)}</p>
        </div>
        <div className="flex justify-between">
          {isPlaying ? (
            <button onClick={handlePause}>
              <svg
                className="w-10 h-10"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM7 8a1 1 0 012 0v4a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v4a1 1 0 102 0V8a1 1 0 00-1-1z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          ) : (
            <button onClick={handlePlay}>
              <svg
                className="w-10 h-10"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          )}
          <div className="flex items-center">
            <div className="flex shrink-0">
              {Boolean(currentPodcast?.spotify) && (
                <a
                  href={currentPodcast?.spotify}
                  target="_blank"
                  rel="noreferrer"
                  className="flex items-center"
                >
                  <span className="text-xs lg:text-xl font-semibold mr-2">
                    Listen on
                  </span>
                  <img
                    alt="play-on-spotify"
                    className="h-4 w-15 lg:h-8 lg:w-auto"
                    src={require("../images/spotify.png").default}
                  />
                </a>
              )}
              {Boolean(currentPodcast?.youtube) && (
                <a
                  href={currentPodcast?.youtube}
                  target="_blank"
                  rel="noreferrer"
                  className="flex items-center"
                >
                  <span className="text-xs lg:text-xl font-semibold mr-2">
                    Listen on
                  </span>
                  <img
                    alt="play-on-youtube"
                    className="h-4 w-13 lg:h-8 lg:w-auto"
                    src={
                      require("../images/yt_logo_rgb_light.png")
                        .default
                    }
                  />
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
