import React from "react";
import { Helmet } from "react-helmet-async";
import Header from "../partials/Header";
import Footer from "../partials/Footer";
import CareerHero from "partials/CareerHero";
import CareerSectionTwo from "partials/CareerSectionTwo";
import CareerSectionThree from "partials/CareerSectionThree";
import CareerSectionFour from "partials/CareerSectionFour";
import CareerSectionFive from "partials/CareerSectionFive";
import CareerSectionSix from "partials/CareerSectionSix";
import CareerCta from "partials/CareerCta";

export default function Career() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      <Helmet>
        <title>Karir</title>
      </Helmet>
      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="grow" style={{ color: '#666666' }}>
        <CareerHero />
        <CareerSectionTwo />
        <CareerSectionThree />
        <CareerSectionFour />
        <CareerSectionFive />
        <CareerSectionSix />
        <CareerCta />
      </main>
      {/*  Site footer */}
      <Footer />
    </div>
  );
}
